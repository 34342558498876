<template>
    <div class="row">
       <div class="col-md-3">
           <h4>Tipo De Usuario <i class="ti ti-user"/></h4>
           <div class="row">
               <div class="col-md-12"><br><br>
                   <tiposgestores-component :placeholderm="'Tipo Usuario'" ref="tipousuario" v-on:cambioTipoGestor="setTipoUsuario"></tiposgestores-component>
               </div>
           </div>
       </div>
       <div v-if="showSelectedRol" class="col-md-4">
           <h4>Rol del tipo usuario <i class="far fa-book-reader"/></h4><br>
           <div v-show="!addRol">
               <button type="button" class="btn btn-outline-warning btn-fw" @click="addR">Agregar <i class="fa fa-plus"/></button>
           </div>
           <div class="row" v-show="addRol">
               <div class="col-md-10">
                   <label><b>Nuevo Rol:</b></label>
                    <input type="text" max-lenght="30" class="form-control" placeholder="Ingrese nuevo Rol" v-model="newRol">
               </div>
               <div class="col-md-2"><br>
                   <i class="far fa-save" style="color:green; cursor: pointer" data-toggle="tooltip" data-placement="bottom" title="Guardar" @click="addNewRol"/> &nbsp;&nbsp;&nbsp;&nbsp;
                   <i class="fas fa-times" style="color:darkred; cursor: pointer" data-toggle="tooltip" data-placement="bottom" title="Cancelar" @click="addR"/>
               </div>
           </div><br>
           <v-client-table :data="tableRoles" :columns="columns" :options="options">
               <div slot="Acc" slot-scope="props">
                   <i class="far fa-arrow-alt-circle-right" style="cursor: pointer;color: darkgoldenrod" @click="asignPermiso(props.row.Id,props.row.Rol)"/>
               </div>
           </v-client-table>
       </div>
       <div v-if="showSelectedPermision" class="col-md-4">
           <h4>Permisos en Aplicación <i class="fas fa-mobile"/></h4><br>
           <div v-if="selectedRol===null">
               <h4 class="text-danger">Por favor seleccione un rol, para asignar permisos</h4>
           </div>
           <div v-else>
               <div class="template-demo text-center">
                   <h4>Permisos para: <b class="text-warning">{{Rol}}</b></h4>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[0].Status==='0', 'btn-outline-success':permisos[0].Status==='1'}" @click="changePermision(0,permisos[0].Status,permisos[0].Id)">
                       Reporte<br>Prioridades<br><br>
                       <i class="fas fa-highlighter"></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[1].Status==='0', 'btn-outline-success':permisos[1].Status==='1'}" @click="changePermision(1,permisos[1].Status,permisos[1].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Faltantes&nbsp;&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-box-open"></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[2].Status==='0', 'btn-outline-success':permisos[2].Status==='1'}" @click="changePermision(2,permisos[2].Status,permisos[2].Id)">
                       Reporte<br>Promociones<br><br>
                       <i class="fas fa-air-freshener"></i>
                   </button>
               </div>
               <div class="template-demo text-center">
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[3].Status==='0', 'btn-outline-success':permisos[3].Status==='1'}" @click="changePermision(3,permisos[3].Status,permisos[3].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Anaquel&nbsp;&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-align-justify"></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[4].Status==='0', 'btn-outline-success':permisos[4].Status==='1'}" @click="changePermision(4,permisos[4].Status,permisos[4].Id)">
                       Reporte<br>Exhibiciones<br><br>
                       <i class="fas fa-sort-amount-up-alt"></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[5].Status==='0', 'btn-outline-success':permisos[5].Status==='1'}" @click="changePermision(5,permisos[5].Status,permisos[5].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Precios&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-money-check-alt"></i>
                   </button>
               </div>
               <div class="template-demo text-center">
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[6].Status==='0', 'btn-outline-success':permisos[6].Status==='1'}" @click="changePermision(6,permisos[6].Status,permisos[6].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Gráfico&nbsp;&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-camera-retro"></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[7].Status==='0', 'btn-outline-success':permisos[7].Status==='1'}" @click="changePermision(7,permisos[7].Status,permisos[7].Id)">
                       <br>&nbsp;&nbsp;Comentario&nbsp;&nbsp;<br><br>
                       <i class="far fa-comments"></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[8].Status==='0', 'btn-outline-success':permisos[8].Status==='1'}" @click="changePermision(8,permisos[8].Status,permisos[8].Id)">
                       Captura <br>&nbsp;&nbsp;Rápida&nbsp;&nbsp;<br><br>
                       <i class="fas fa-arrows-alt-h"></i>
                   </button>
               </div>
               <div class="template-demo text-center">
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[9].Status==='0', 'btn-outline-success':permisos[9].Status==='1'}" @click="changePermision(9,permisos[9].Status,permisos[9].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Mercadeo&nbsp;&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-store-alt "></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[10].Status==='0', 'btn-outline-success':permisos[10].Status==='1'}" @click="changePermision(10,permisos[10].Status,permisos[10].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Campañas&nbsp;&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-swatchbook"></i>
                   </button>
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[11].Status==='0', 'btn-outline-success':permisos[11].Status==='1'}" @click="changePermision(11,permisos[11].Status,permisos[11].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Ventas&nbsp;&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-file-invoice-dollar "></i>
                   </button>
               </div>
               <div class="template-demo text-center">
                   <button type="button" class="btn btn-icon-text" :class="{'btn-outline-danger':permisos[12].Status==='0', 'btn-outline-success':permisos[12].Status==='1'}" @click="changePermision(12,permisos[12].Status,permisos[12].Id)">
                       Reporte<br>&nbsp;&nbsp;&nbsp;Inventario&nbsp;&nbsp;&nbsp;&nbsp;<br><br>
                       <i class="fas fa-truck-loading "></i>
                   </button>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
    export default {
        name: "UsuariosPermisosAplicacion",
        data(){
            return{
                permisos:[],
                addRol:false,
                newRol:'',
                columns: ['Id', 'Rol','Acc'],
                tableTipos: [],
                tableRoles: [],
                options: {
                    // see the options API
                    footerHeadings:false,
                    filterable:false,
                    filterByColumn:false,
                    perPage: 10,
                    perPageValues: [10,20,40,120],
                    listColumns: {
                        Status: [{
                            id: 'Pending',
                            text: 'Pending'
                        },
                        ]
                    },
                    headings: {
                        Cvetpousr:'Id:',
                        Tpousr:'Tipo Usuario:',
                        Acc:'Acción:',
                    },
                    columnsClasses: {
                        Cvetpousr: 'text-center',
                        Tpousr: 'text-center',
                        Acc: 'text-center',
                    },
                    texts: {
                        count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                        first: 'Primero',
                        last: 'Último',
                        filter: "Filtro:",
                        filterPlaceholder: "Buscar:",
                        limit: "Registros:",
                        page: "Pagína:",
                        noResults: "No hay resultados, intente nuevamente",
                        filterBy: "Filtrar por {column}",
                        loading: 'Buscando...',
                        defaultOption: 'Todos',
                        columns: 'Columnas'
                    },
                },
                selectedRol:null,
                Rol:'',
                tipoUsuario:null,
                showSelectedRol:false,
                showSelectedPermision:false,
            }
        },
        mounted() {
            this.getData();
        },
        methods:{
            getRolesUsers(){let $this = this;
                axios.post('/api/AdminCatalogos/getRolesUsers',{tipouser:this.tipoUsuario})
                    .then(r => {
                            if (r.data.Success){
                                $this.tableRoles= r.data.Data;
                                $this.showSelectedRol = true;
                            }
                        },
                        error => {
                            console.error(error)
                        }
                    );
            },
            setTipoUsuario(){ let $this=this;this.tableRoles=[];
                var a = this.$refs.tipousuario.selectedTiposGestores;
                if(a !== null){
                    this.tipoUsuario = a.value;
                    this.showSelectedPermision=false;
                    axios.post('/api/AdminCatalogos/getRolesUsers',{tipouser:this.tipoUsuario})
                        .then(r => {
                                if (r.data.Success){
                                    $this.tableRoles= r.data.Data;
                                    $this.showSelectedRol = true;
                                }
                            },
                            error => {
                                console.error(error)
                            }
                        );
                }
                else{
                    this.showSelectedRol = false;
                    this.showSelectedPermision=false;
                    this.tipoUsuario = a;
                }
            },
            getData(){let $this=this; this.tableTipos=[];
                axios.post('/api/AdminCatalogos/getTpoUsers',{})
                .then(r => {
                    if (r.data.Success){
                        $this.tableTipos= r.data.Data;
                    }
                },
                error => {
                    console.error(error)
                }
                );

            },
            addR(){
                this.newRol='';
                this.addRol=!this.addRol;
            },
            addNewRol(){let $this = this;
                axios.post('/api/AdminCatalogos/addNewRol',{
                    rol:this.newRol,tipousuario:this.tipoUsuario
                })
                    .then(r => {
                        if (r.data.Success){
                            $this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                icon: 'success',
                                title: r.data.Mensaje
                            });
                            $this.getRolesUsers();
                            $this.newRol='';
                            $this.addRol=false;
                        }
                    },
                    error => {
                        console.error(error)
                    }
                );
            },
            asignPermiso(id,name){let $this=this; this.permisos=[];
                axios.post('/api/AdminCatalogos/getPermisosApk',{
                    id:id,tipousuario:this.tipousuario
                })
                    .then(r => {
                            if (r.data.Success){
                                $this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    icon: 'success',
                                    title: r.data.Mensaje
                                });
                                $this.permisos = r.data.Permisos;
                                $this.selectedRol=id;
                                $this.Rol=name;
                                $this.showSelectedPermision=true;
                            }
                        },
                        error => {
                            console.error(error)
                        }
                    );
            },
            changePermision(index,status,id){
                var newstatus = status==='0'?1:0;
                let $this=this;
                axios.post('/api/AdminCatalogos/changePermisoApk',{
                    id:id, status:newstatus
                })
                .then(r => {
                        if (r.data.Success){
                            $this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                icon: 'success',
                                title: r.data.Mensaje
                            });
                            $this.permisos[index].Status = ''+newstatus+'';
                        }
                    },
                    error => {
                        console.error(error)
                    }
                );

            }
        }
    }
</script>

<style scoped>

</style>
