<template>
    <div>
       <div class="row">
           <div class="col-md-3">
               <h5 class="text-info">Cargar archivo de productos Prometeo</h5>
               <div class="form-group">
                   <label class="control-label">Archivo</label>
                   <div>
                       <input type="file"  id="archivo" name="archivo" class="form-control" required="" ref="fileRutas" v-on:change="handleFileUpload()"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                   </div>
               </div>
               <button v-if="!loadfile" class="btn btn-primary text-center" type="button" @click="submitFile()"><strong>Cargar archivo</strong></button><br><br>
               <div v-if="loadfile" class="text-center">
                   <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                   <p>Procesando archivo, espere...</p>
               </div>
           </div>
           <div class="col-md-9 text-center">
               <div class="row">
                   <div class="col-md-3">
                       <span class="text-center">Departamentos Alta: {{dataCanal.length}}</span>
                       <v-client-table :data="dataCanal" :columns="columnsCanales" :options="optionsCanales" class="table-responsive">
                       </v-client-table>
                   </div>
                   <div class="col-md-3">
                       <span class="text-center">Fabricantes Alta: {{dataCadena.length}}</span>
                       <v-client-table :data="dataCadena" :columns="columnsCadena" :options="optionsCadena" class="table-responsive">
                       </v-client-table>
                   </div>
                   <div class="col-md-3">
                       <span class="text-center">Categorias Alta: {{dataFormato.length}}</span>
                       <v-client-table :data="dataFormato" :columns="columnsFormato" :options="optionsFormatos" class="table-responsive">
                       </v-client-table>
                   </div>
                   <div class="col-md-3">
                       <span class="text-center">Marcas Alta: {{dataFormato.length}}</span>
                       <v-client-table :data="dataFormato" :columns="columnsFormato" :options="optionsFormatos" class="table-responsive">
                       </v-client-table>
                   </div>
               </div>
           </div>
       </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-3">
                        <div class="text-center">
                            <b style="color: red">Estados Incorrectos</b>
                        </div>
                        <ul>
                            <li v-for="items in dataEstados"><span class="badge badge-success">{{items.Estado}}</span></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "importLayoutProductos",
    data(){
        return{
            loadfile:false,
            file: '',
            columnsCanales: ['Canal'],
            dataCanal:[],
            optionsCanales: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:4,
                perPageValues: [4,10,15,25,45,50],
                filterable: ['Canal'],
                columnsDropdown:false,
                headings: {
                },
                columnsClasses: {
                    Canal: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsCadena: ['Canal','Cadena'],
            dataCadena:[],
            optionsCadena: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:4,
                perPageValues: [4,10,15,25,45,50],
                filterable: ['Canal','Cadena'],
                columnsDropdown:false,
                headings: {
                },
                columnsClasses: {
                    Canal: 'text-justify',
                    Cadena: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsFormato: ['Canal','Cadena','Formato'],
            dataFormato:[],
            optionsFormatos: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:4,
                perPageValues: [4,10,15,25,45,50],
                filterable: ['Canal','Cadena','Formato'],
                listColumns: {
                    Estado:[]
                },
                columnsDropdown:false,
                columnsClasses: {
                    Canal: 'text-justify',
                    Cadena: 'text-justify',
                    Formato: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            dataEstados:[],
            nameFile:''
        }
    },
    mounted() {
    },
    methods:{
        handleFileUpload(){
            this.file = this.$refs.fileRutas.files[0];
        },
        submitFile(){ let $this = this;
            if (this.file === ''){
                $this.$toastMessage('error','Seleccione un archivo XLSX para procesar',4000,'center');
            }
            else{
                this.loadfile = true;
                let formData = new FormData();
                /*
                    Add the form data we need to submit
                */
                formData.append('file', this.file);
                /*
                  Make the request to the POST /single-file URL
                */
                axios.post( '/api/Productos/importPrometeo',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(r){
                    if (r.data.Success){
                        $this.$swal.fire({
                            icon: 'success',
                            title: 'ÉXITO',
                            text:  r.data.Mensaje,
                            footer: '<b>puede ver el resumen ahora!</b>'
                        });
                        $this.nameFile = r.data.File;
                        $this.downlodFileResult();
                    }
                    else{
                        $this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Archivo procesado con errores!',
                            footer: '<b>' + r.data.Mensaje + '</b>'
                        });
                    }
                    $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                })
                    .catch(function(){
                        $this.$toastMessage('error','Error no definido, reportar a IT',4000,'center');
                        $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                    });
            }

        },
        downlodFileResult(){
            let $this = this;
            this.loadfile = true;
            axios({
                method: 'post',
                url: '/api/Productos/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {file: this.nameFile}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ResumenProductosPoss.xlsx'
                    link.click()
                    $this.loadfile=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfile=false;
                }
            );
        }
    }
}
</script>

<style scoped>

</style>
