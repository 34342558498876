<template>
    <div>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <kpi-select ref="kpiselect" :placeholderm="'KPI'" v-on:cambioKpi="getData"></kpi-select>
            </div>
            <div class="col-md-4" v-if="showButton">
                <button class="btn btn-with-icon btn-primary" @click="getFile"><i class="fas fa-download"></i> Descargar Reporte</button>
            </div>
            <div class="col-md-2"></div>
        </div>
        <br/><br/>
    </div>
</template>

<script>
export default {

    name: "kpi",
    data(){
        return{
            showButton:false,
            idReport:0,
        }
    },
    methods:{
        getData(){
            if (this.$refs.kpiselect.selectedKpi != null){
                this.idReport = this.$refs.kpiselect.selectedKpi.value;
                this.showButton=true;
            }else{this.showButton=false;this.idReport = 0;}

        },
        async getFile(){
            let r = await this.$peticionesApiFilesDownload('/api/KPI/exportLayoutToExcel','kpiPoss.xlsx',this.idReport);
        }
    }
}
</script>

<style scoped>

</style>
