<template>
    <div class="border"><br><br>
        <div class="row">
            <div class="col-md-12">
                <div class="text-center">
                    <h2><span class="text-primary">SHARE DE EXHIBICIÓN</span></h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label><b>Año:</b></label><br>
                <select-anios ref="selectedAnio" :placeholderm="'Año'" v-on:cambioAnio="setAnio" ></select-anios>
            </div>
            <div class="col-md-1">
                <label><b>Áreas:</b></label>
                <select-areasN placeholderm="Área" v-on:cambioArea="changeArea" ref="selectAreas"></select-areasN>
            </div>
            <div class="col-md-2">
                <label><b>Canales:</b></label>
                <select-canalesN placeholderm="Canal" v-on:cambioCanal="changeCanal" ref="selectCanal"></select-canalesN>
            </div
            ><div class="col-md-3">
                <label><b>Cadenas:</b></label>
                <select-cadenasN placeholderm="Cadena" v-on:cambioCadena="changeCadena" ref="selectCadena" :desa="false"></select-cadenasN>
            </div>
            <div class="col-md-2">
                <label><b>Categorias:</b></label>
                <select-categoriasN placeholderm="Categoria" v-on:cambioCategoria="changeCategoria" ref="selectCategoria"></select-categoriasN>
            </div>
            <div class="col-md-2"><br>
                <button class="btn btn-primary dim text-center" type="button" @click="getReport">
                    <i class="fa fa-cogs"> Generar Reporte</i>
                </button>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <div v-if="showLoad" class="text-center">
                    <img :src="'/theme/img/loads/spiralload.gif'" alt="carga reporte" height="40">
                    <p>Consultando base de datos, por favor espere...</p>
                </div>
            </div>
            <div class="col-md-12">
                <br>
                <div v-if="dataReport.length>0">
                    <div v-for="items in dataReport">
                        <div v-for="itens in items.Data">
                            <div class="text-center">
                                <h5>{{items.Categoria}} - {{itens.Cadena}} &nbsp;<span class="badge badge-primary">{{itens.Tipo}}</span></h5>
                            </div>
                            <br>
                            <share-grafica :metaData="[itens.Data]"  ref="graficaresult"></share-grafica>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "shareExhibiciones",
    data(){
        return{
            showLoad:false,
            filters:{
                cadenasReal:null,
                categoriasReal:null,
                anio:null,
                areasReal:null,
                canalesReal:null,
            },
            dataReport:[],
        }
    },
    mounted() {
        this.setAnio();
    },
    methods:{
        changeCanal(){
            var canal = this.$refs.selectCanal.selectedCanales;
            var selectTodas = false;
            if (canal.length>0){
                canal.forEach(function (value,key){
                    if (canal[key].value === 0){
                        selectTodas = true;
                    }
                });
                var cadena = this.$refs.selectCadena;
                if (selectTodas){
                    cadena.canal = this.$refs.selectCanal.optionsCanales;
                    this.filters.canalesReal = this.$refs.selectCanal.optionsCanales;
                    cadena.getCadenas();
                }
                else{
                    cadena.canal = canal;
                    this.filters.canalesReal = canal;
                    cadena.getCadenas();
                }
            }
            else{
                this.filters.canalesReal = null;
            }
        },
        changeArea(){
            var area = this.$refs.selectAreas.selectedAreas;
            var selectTodas = false;
            if (area.length>0){
                area.forEach(function (value,key){
                    if (area[key].value === 0){
                        selectTodas = true;
                    }
                });

                if (selectTodas){
                    this.filters.areasReal = this.$refs.selectAreas.optionsAreas;

                }
                else{
                    this.filters.areasReal = area;
                }
            }
            else{
                this.filters.areasReal = null;
            }
        },
        changeCadena(){
            var cadenas = this.$refs.selectCadena.selectedCadenas;
            var selectTodas = false;
            if (cadenas.length>0){
                cadenas.forEach(function (value,key){
                    if (cadenas[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.cadenasReal = this.$refs.selectCadena.optionsCadenas;
                }
                else{
                    this.filters.cadenasReal = cadenas;
                }
            }
            else{
                this.filters.cadenasReal = null;
            }
        },
        changeCategoria(){let $this = this;
            var categoria = this.$refs.selectCategoria.selectedCategorias;
            var selectTodas = false;
            if (categoria.length>0){
                categoria.forEach(function (value,key){
                    if (categoria[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.categoriasReal = this.$refs.selectCategoria.optionsCategorias;
                }
                else{
                    this.filters.categoriasReal = categoria;

                }
            }
            else{
                this.filters.categoriasReal = null;
            }
        },
        setAnio(){
            this.filters.anio = this.$refs.selectedAnio.selectedAnios;
        },
        getReport(){
            this.showLoad = true;
            if (this.filters.anio === null){
                this.$toastMessage('error','Selecciona el Año por favor!',5000,'center');
                this.showLoad = false;
            }
            else if (this.filters.cadenasReal === null){
                this.$toastMessage('error','Selecciona por lo menos una Cadena por favor!',9000,'center');
                this.showLoad = false;
            }
            else if (this.filters.categoriasReal === null){
                this.$toastMessage('error','Selecciona por lo menos una Categoria por favor!',9000,'center');
                this.showLoad = false;
            }
            else
            {let $this= this; $this.dataReport = [];
                axios.post('/api/Dashboard/shareExhibiciones',{data:this.filters})
                    .then(r => {
                            if (r.data.Success){
                                $this.showLoad=false;
                                $this.dataReport = r.data.Data;
                            }
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center'); $this.showLoad=false;}
                    );
            }
        }
    }
}
</script>

<style scoped>

</style>
