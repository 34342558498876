<template>
    <div><br><br>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-2">
                        <label>Año:</label>
                        <select-anios :placeholderm="'Año'" ref="selectAnio" v-on:cambioAnio="getData"></select-anios>
                    </div>
                    <div class="col-md-2">
                        <label>Mes:</label>
                        <select-mes :placeholderm="'Mes'" ref="selectMess" v-on:cambioMes="getData"></select-mes>
                    </div>
                    <div class="col-md-2">
                        <label>Canal:</label>
                        <select-canal :placeholderm="'Canal'" :multiples="false" ref="selectCanal" v-on:cambioCanal="getData"></select-canal>
                    </div>
                    <div class="col-md-2">
                        <h4>Inicio: {{this.reporteG.incio}}</h4>
                        <h4>Fin: {{this.reporteG.fin}}</h4>
                    </div>
                </div><br>
                <div v-if="loadingGetData" class="text-center">
                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                    <p>Preparando información, espere...</p>
                </div>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <div class="text-center">
                            <h3><b>REPORTE GENERAL</b></h3>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card custom-card">
                                    <div class="card-body text-center">
                                        <div class="icon-service bg-info-transparent rounded-circle text-info">
                                            <i class="fas fa-people-carry"></i>
                                        </div>
                                        <p class="mb-1 text-muted">Eficiencia</p>
                                        <h3 class="mb-0">{{ reporteG.efg }}<span>%</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card custom-card">
                                    <div class="card-body text-center">
                                        <div class="icon-service bg-info-transparent rounded-circle text-info">
                                            <i class="fas fa-store"></i>
                                        </div>
                                        <p class="mb-1 text-muted">Visitadas</p>
                                        <h3 class="mb-0">{{ reporteG.vg }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-right">
                                <button class="btn ripple btn-danger btn-block"><small>Faltas {{reporteG.faltasg}}</small></button>
                                <button class="btn ripple btn-primary btn-block" style="margin-top: -1px;"><small>No Visitadas {{reporteG.nvg}}</small></button>
                                <button class="btn ripple btn-warning btn-block" style="margin-top: -1px;"><small>Canceladas {{reporteG.cg}}</small></button>
                                <button class="btn ripple btn-danger disabled btn-block" style="margin-top: -1px;"><small>T Pendiente {{reporteG.pg}}</small></button>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-12">
                                <highcharts :options="chartOptions"></highcharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
function createChartESNormal(vm){
    return{
        chart: {
            type: 'bar',
            height: 0,
},
    scrollbar:{
        enabled:false
    },
    title: {
        text: 'REPORTE GENERAL - EFICIENCIA POSS'
    },
    xAxis: {
        categories: [],
        scrollbar: {
            enabled: false
        },
    },
    yAxis: {
        min: 0,
            max: 100,
            title: {
            enabled: false
        },
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            grouping: false
        },
        series: {
            pointWidth: 18,
                //stacking: 'percent',
                dataLabels: {
                enabled: true,
                    inside: true,
                    align: 'right',
                    color: '#fff',
                    style: {
                    textShadow: false,
                        textOutline: "0px"
                }
            }
        }
    },
    credits:{
        text: 'GRUPO PROMOCIONES',
            href: 'https://grupopromociones.com'
    },
    tooltip: {
        useHTML: true,
            shared: true,
            formatter: function() {
            return '<i>' + this.points[1].x +'</i>: <b>'+ this.points[1].y +'%</b>';
        }
    },
    series: [
        {
            name: 'background filler',
            data: [],
    color:'#ed5565',
        animation: false,
        dataLabels: {
        enabled: false
    }
},
    {
        name: 'actual Value',
        data: [],
        color:'#1ab394',
        dataLabels: {
        enabled: true,
            formatter: function(){
            return this.y + '%';
        }
    }
    }]
    }
}
function createChartTIPS(vm){
    return{
        chart: {
            type: 'bar',
            height: 0,
},
    scrollbar:{
        enabled:false
    },
    title: {
        text: 'REPORTE PMA - EFICIENCIA POSS'
    },
    xAxis: {
        categories: [],
        scrollbar: {
            enabled: false
        },
    },
    yAxis: {
        min: 0,
            max: 100,
            title: {
            enabled: false
        },
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            grouping: false
        },
        series: {
            pointWidth: 18,
                //stacking: 'percent',
                dataLabels: {
                enabled: true,
                    inside: true,
                    align: 'right',
                    color: '#fff',
                    style: {
                    textShadow: false,
                        textOutline: "0px"
                }
            }
        }
    },
    credits:{
        text: 'GRUPO PROMOCIONES',
            href: 'https://grupopromociones.com'
    },
    tooltip: {
        useHTML: true,
            shared: true,
            formatter: function() {
            return '<i>' + this.points[1].x +'</i>: <b>'+ this.points[1].y +'%</b>';
        }
    },
    series: [
        {
            name: 'background filler',
            data: [],
    color:'#ed5565',
        animation: false,
        dataLabels: {
        enabled: false
    }
},
    {
        name: 'actual Value',
        data: [],
        color:'#1ab394',
        dataLabels: {
        enabled: true,
            formatter: function(){
            return this.y + '%';
        }
    }
    }]
    }
}
function createChartSIGMA(vm){
    return{
        chart: {
            type: 'bar',
            height: 0,
},
    scrollbar:{
        enabled:false
    },
    title: {
        text: 'REPORTE WAVEMARK - EFICIENCIA POSS'
    },
    xAxis: {
        categories: [],
        scrollbar: {
            enabled: false
        },
    },
    yAxis: {
        min: 0,
            max: 100,
            title: {
            enabled: false
        },
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            grouping: false
        },
        series: {
            pointWidth: 18,
                //stacking: 'percent',
                dataLabels: {
                enabled: true,
                    inside: true,
                    align: 'right',
                    color: '#fff',
                    style: {
                    textShadow: false,
                        textOutline: "0px"
                }
            }
        }
    },
    credits:{
        text: 'GRUPO PROMOCIONES',
            href: 'https://grupopromociones.com'
    },
    tooltip: {
        useHTML: true,
            shared: true,
            formatter: function() {
            return '<i>' + this.points[1].x +'</i>: <b>'+ this.points[1].y +'%</b>';
        }
    },
    series: [
        {
            name: 'background filler',
            data: [],
    color:'#ed5565',
        animation: false,
        dataLabels: {
        enabled: false
    }
},
    {
        name: 'actual Value',
        data: [],
        color:'#1ab394',
        dataLabels: {
        enabled: true,
            formatter: function(){
            return this.y + '%';
        }
    }
    }]
    }
}
function createChartGG(vm){
    return{
        chart: {
            type: 'bar',
            height: 0,
},
    scrollbar:{
        enabled:false
    },
    title: {
        text: 'REPORTE GLOW & GROW - EFICIENCIA POSS'
    },
    xAxis: {
        categories: [],
        scrollbar: {
            enabled: false
        },
    },
    yAxis: {
        min: 0,
            max: 100,
            title: {
            enabled: false
        },
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            grouping: false
        },
        series: {
            pointWidth: 18,
                //stacking: 'percent',
                dataLabels: {
                enabled: true,
                    inside: true,
                    align: 'right',
                    color: '#fff',
                    style: {
                    textShadow: false,
                        textOutline: "0px"
                }
            }
        }
    },
    credits:{
        text: 'GRUPO PROMOCIONES',
            href: 'https://grupopromociones.com'
    },
    tooltip: {
        useHTML: true,
            shared: true,
            formatter: function() {
            return '<i>' + this.points[1].x +'</i>: <b>'+ this.points[1].y +'%</b>';
        }
    },
    series: [
        {
            name: 'background filler',
            data: [],
    color:'#ed5565',
        animation: false,
        dataLabels: {
        enabled: false
    }
},
    {
        name: 'actual Value',
        data: [],
        color:'#1ab394',
        dataLabels: {
        enabled: true,
            formatter: function(){
            return this.y + '%';
        }
    }
    }]
    }
}
function createChartALFA(vm){
    return{
        chart: {
            type: 'bar',
            height: 0,
},
    scrollbar:{
        enabled:false
    },
    title: {
        text: 'REPORTE ALFA - EFICIENCIA POSS'
    },
    xAxis: {
        categories: [],
        scrollbar: {
            enabled: false
        },
    },
    yAxis: {
        min: 0,
            max: 100,
            title: {
            enabled: false
        },
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            grouping: false
        },
        series: {
            pointWidth: 18,
                //stacking: 'percent',
                dataLabels: {
                enabled: true,
                    inside: true,
                    align: 'right',
                    color: '#fff',
                    style: {
                    textShadow: false,
                        textOutline: "0px"
                }
            }
        }
    },
    credits:{
        text: 'GRUPO PROMOCIONES',
            href: 'https://grupopromociones.com'
    },
    tooltip: {
        useHTML: true,
            shared: true,
            formatter: function() {
            return '<i>' + this.points[1].x +'</i>: <b>'+ this.points[1].y +'%</b>';
        }
    },
    series: [
        {
            name: 'background filler',
            data: [],
    color:'#ed5565',
        animation: false,
        dataLabels: {
        enabled: false
    }
},
    {
        name: 'actual Value',
        data: [],
        color:'#1ab394',
        dataLabels: {
        enabled: true,
            formatter: function(){
            return this.y + '%';
        }
    }
    }]
    }
}
export default {
    name: "general",
    data(){
        return{
            loadingGetData:false,
            filtros:{
                anio:null,
                mes:null,
                canal:null,
            },
            reporteG:{
                incio:'',
                fin:'',
                efg:0,
                vg:0,
                faltasg:0,
                nvg:0,
                cg:0,
                pg:0,
            },
            reporteTips:{
                efg:0,
                vg:0,
                faltasg:0,
                nvg:0,
                cg:0,
                pg:0,
            },
            reporteSigma:{
                efg:0,
                vg:0,
                faltasg:0,
                nvg:0,
                cg:0,
                pg:0,
            },
            reporteGG:{
                efg:0,
                vg:0,
                faltasg:0,
                nvg:0,
                cg:0,
                pg:0,
            },
            reporteALFA:{
                efg:0,
                vg:0,
                faltasg:0,
                nvg:0,
                cg:0,
                pg:0,
            },
            chartOptions: createChartESNormal(this),
            chartOptionsTIPS: createChartTIPS(this),
            chartOptionsSIGMA: createChartSIGMA(this),
            chartOptionsGG: createChartGG(this),
            chartOptionsALFA: createChartALFA(this),
        }
    },
    mounted() {
        let $this = this;
        setTimeout($this.getData(), 2000);

    },
    methods:{
        getData(){ let $this = this;
            this.loadingGetData=true;
            this.filtros.anio = this.$refs.selectAnio.selectedAnios;
            this.filtros.mes = this.$refs.selectMess.selectedMes;
            this.filtros.canal = this.$refs.selectCanal.selectedCanales;
            axios.post('/api/Dashboard/eficienciaGeneral',{data:this.filtros})
                .then(r => {
                        if (r.data.Success){
                            $this.reporteG.incio=r.data.Inicio;
                            $this.reporteG.fin=r.data.Fin;
                            $this.reporteG.efg=r.data.EfiG;
                            $this.reporteG.vg=r.data.Vg;
                            $this.reporteG.faltasg=r.data.faltasG;
                            $this.reporteG.nvg=r.data.noVisitadasG;
                            $this.reporteG.cg=r.data.canceladasG;
                            $this.reporteG.pg=r.data.pendientesG;
                            $this.chartOptions.chart.height=r.data.heightT;
                            $this.chartOptions.xAxis.categories = [];
                            $this.chartOptions.series[0].data = [];
                            $this.chartOptions.series[1].data = [];
                            r.data.fechas.forEach(function (value,key){
                                $this.chartOptions.xAxis.categories.push(value.Fechacierre);
                                $this.chartOptions.series[0].data.push(100);
                                $this.chartOptions.series[1].data.push(value.Eficiencia);
                            });


                            $this.reporteTips.efg=r.data.EfiTIPS;
                            $this.reporteTips.vg=r.data.VTIPS;
                            $this.reporteTips.faltasg=r.data.faltasTIPS;
                            $this.reporteTips.nvg=r.data.noVisitadasTIPS;
                            $this.reporteTips.cg=r.data.canceladasTIPS;
                            $this.reporteTips.pg=r.data.pendientesTIPS;
                            $this.chartOptionsTIPS.chart.height=r.data.heightT;
                            $this.chartOptionsTIPS.xAxis.categories = [];
                            $this.chartOptionsTIPS.series[0].data = [];
                            $this.chartOptionsTIPS.series[1].data = [];
                            r.data.fechasTIPS.forEach(function (value,key){
                                $this.chartOptionsTIPS.xAxis.categories.push(value.Fechacierre);
                                $this.chartOptionsTIPS.series[0].data.push(100);
                                $this.chartOptionsTIPS.series[1].data.push(value.Eficiencia);
                            });

                            $this.reporteSigma.efg=r.data.EfiSIGMA;
                            $this.reporteSigma.vg=r.data.VSIGMA;
                            $this.reporteSigma.faltasg=r.data.faltasSIGMA;
                            $this.reporteSigma.nvg=r.data.noVisitadasSIGMA;
                            $this.reporteSigma.cg=r.data.canceladasSIGMA;
                            $this.reporteSigma.pg=r.data.pendientesSIGMA;
                            $this.chartOptionsSIGMA.chart.height=r.data.heightT;
                            $this.chartOptionsSIGMA.xAxis.categories = [];
                            $this.chartOptionsSIGMA.series[0].data = [];
                            $this.chartOptionsSIGMA.series[1].data = [];
                            r.data.fechasSIGMA.forEach(function (value,key){
                                $this.chartOptionsSIGMA.xAxis.categories.push(value.Fechacierre);
                                $this.chartOptionsSIGMA.series[0].data.push(100);
                                $this.chartOptionsSIGMA.series[1].data.push(value.Eficiencia);
                            });

                            $this.reporteGG.efg=r.data.EfiGG;
                            $this.reporteGG.vg=r.data.VSGG;
                            $this.reporteGG.faltasg=r.data.faltasGG;
                            $this.reporteGG.nvg=r.data.noVisitadasGG;
                            $this.reporteGG.cg=r.data.canceladasGG;
                            $this.reporteGG.pg=r.data.pendientesGG;
                            $this.chartOptionsGG.chart.height=r.data.heightT;
                            $this.chartOptionsGG.xAxis.categories = [];
                            $this.chartOptionsGG.series[0].data = [];
                            $this.chartOptionsGG.series[1].data = [];
                            r.data.fechasGG.forEach(function (value,key){
                                $this.chartOptionsGG.xAxis.categories.push(value.Fechacierre);
                                $this.chartOptionsGG.series[0].data.push(100);
                                $this.chartOptionsGG.series[1].data.push(value.Eficiencia);
                            });

                            $this.reporteALFA.efg=r.data.EfiALFA;
                            $this.reporteALFA.vg=r.data.VSALFA;
                            $this.reporteALFA.faltasg=r.data.faltasALFA;
                            $this.reporteALFA.nvg=r.data.noVisitadasALFA;
                            $this.reporteALFA.cg=r.data.canceladasALFA;
                            $this.reporteALFA.pg=r.data.pendientesALFA;
                            $this.chartOptionsALFA.chart.height=r.data.heightT;
                            $this.chartOptionsALFA.xAxis.categories = [];
                            $this.chartOptionsALFA.series[0].data = [];
                            $this.chartOptionsALFA.series[1].data = [];
                            r.data.fechasALFA.forEach(function (value,key){
                                $this.chartOptionsALFA.xAxis.categories.push(value.Fechacierre);
                                $this.chartOptionsALFA.series[0].data.push(100);
                                $this.chartOptionsALFA.series[1].data.push(value.Eficiencia);
                            });

                            $this.loadingGetData=false;
                        }
                    },
                    error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    }
                );
        }
    }
}
</script>

<style scoped>

</style>
