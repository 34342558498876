<template>
    <div>
        <div class="example">
            <div class="text-center">
                <h2><span class="text-primary">NIVEL DE EJECUCIÓN DE PRIORIDADES</span></h2>
            </div>
            <div class="">
                <div class="bg-gray-300 nav-bg" style="display:none">
                    <nav class="nav nav-tabs">
                        <a class="nav-link active" data-toggle="tab" href="#tabCont1" ref="general">General <i class="fas fa-chart-pie"></i></a>
                        <a class="nav-link" data-toggle="tab" href="#tabCont2" ref="infor">Información <i class="fas fa-table"></i></a>
                        <a class="nav-link" data-toggle="tab" href="#tabCont3" ref="galeri">Galería <i class="fas fa-images"></i></a>
                    </nav>
                </div>
                <div class="card-body tab-content">
                    <div class="tab-pane active show" id="tabCont1">
                        <div class="text-center">
                            <encuestas-general v-on:cambioPagina="changePage(true)" ref="encuesta"></encuestas-general>
                        </div>
                    </div>
                    <div class="tab-pane" id="tabCont2">
                        <div class="text-center">
                            <encuestas-detalle ref="detalle" v-on:cambioPagina="changePage(false)" v-on:cambioGaleria="changePageGaleria(true)"></encuestas-detalle>
                        </div>
                    </div>
                    <div class="tab-pane" id="tabCont3">
                        <div class="text-center">
                            <encuestas-galeria ref="galeria" v-on:cambioGaleria="changePageGaleria(false)"></encuestas-galeria>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import encuestasGeneral from '../Encuestas/general'
import encuestasDetalle from '../Encuestas/detalle'
import encuestasGaleria from '../Encuestas/galeria'
export default {
    name: "contenedor",
    components:{
        "encuestas-general": encuestasGeneral,
        "encuestas-detalle": encuestasDetalle,
        "encuestas-galeria": encuestasGaleria
    },
    data(){
        return{

        }
    },
    mounted() {
    },
    methods:{
        changePage(type){
            if (type){
                this.$refs.infor.click()
                var cve = this.$refs.encuesta.cve;
                var name = this.$refs.encuesta.Pregunta + ' Tiendas: ' + this.$refs.encuesta.tiendass;
                var inicio = this.$refs.encuesta.Inicio;
                var fin = this.$refs.encuesta.Fin;
                this.$refs.detalle.nameEnct = name;
                //this.$refs.detalle.selectedSemana = {label:"GENERAL",value:null,ini:null};
                this.$refs.detalle.vigencia = inicio + ' - ' + fin;
                this.$refs.detalle.getWeeks(cve);
            }
            else{
                this.$refs.general.click()
            }
        },
        changePageGaleria(type){
            if (type){
                this.$refs.galeri.click()
                var cve = this.$refs.encuesta.cve;
                this.$refs.galeria.cveEncuesta = cve;
                this.$refs.galeria.getData();
            }
            else{
                this.$refs.infor.click()
            }
        }
    }
}
</script>

<style scoped>

</style>
