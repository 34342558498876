<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-2">
                        <label><b>Año:</b></label><br>
                        <selectanio-faltantes :placeholderm="'Año'" v-on:cambioAnio="changeAnio" ref="anioSelect"></selectanio-faltantes>
                    </div>
                    <div class="col-md-3">
                        <label><b>Semana</b></label><br>
                        <selectaniosemana-faltantes :placeholderm="'Semana'" v-on:cambioSemana="changeSemana" ref="semanaSelect"></selectaniosemana-faltantes>
                    </div>
                    <!--<div class="col-md-2">

                       <label class="typo__label"><b>Canales:</b></label>
                       <select-canalesN :placeholderm="'Canal'" ref="selectCanal" v-on:cambioCanal="changeCanal"></select-canalesN>- ->
                    </div>
                    <div class="col-md-2">

                        <label class="typo__label">Cadenas:</label>
                        <select-cadenasN :placeholderm="'Cadena'" ref="selectCadena" v-on:cambioCadena="changeCadena"></select-cadenasN>- ->
                    </div>-->
                    <div class="col-md-4 text-center"><br>
                        <button class="btn btn-primary dim text-center" type="button" @click="getReportDetalle">
                            <i class="fa fa-cogs"> Generar Reporte</i>
                        </button>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-12">
                <div v-if="showLoad" class="text-center">
                    <img :src="'/theme/img/loads/spiralload.gif'" alt="carga reporte" height="40">
                    <p>Consultando base de datos, por favor espere...</p>
                </div>
                <div v-else>
                    <highcharts :options="chartOptions"></highcharts>
                    <div v-show="showTable">
                        <table class="table table-striped mg-b-0">
                            <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Total</th>
                                <th>Tienda</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="items in tableDataPtos">
                                <th scope="row">{{items.Codigo}}</th>
                                <td>{{items.Producto}}</td>
                                <td class="text-center tx-bold text-danger">{{items.Total}}</td>
                                <td class="text-justify">
                                    <span v-for="itemsT in items.Data">{{itemsT.Kcmid}} / {{itemsT.Fecha}}<br></span>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
function createChartFaltantes(vm){
    return {
        chart: {
            type: 'column',
            zoomType: 'x'
        },
        legend:{ enabled:false },
        title: {
            text: 'Faltantes 80/20'
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: []
        },
        yAxis: {
            allowDecimals: false,
                min: 0,
                title: {
                text: 'Tiendas Con Faltantes'
            }
        },
        tooltip: {
            formatter: function () {
                return '<b>' + this.x + '</b><br/>' +
                    this.series.name + ': ' + this.y + '<br/>' +
                    'Total: ' + this.point.stackTotal + ' Tiendas';
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },credits:{
            text: 'GRUPO PROMOCIONES',
                href: 'https://grupopromociones.com'
        },
        series: []
        }
}
export default {
    name: "faltantesOchenta",
    data(){
        return{
            showTable:false,
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: false,
            },
            showLoad:true,
            value6: new Date(),
            selectTodosCanales:false,
            selectTodasCadenas:false,
            optionsCanalesReal:[],
            optionsCadena:[],
            optionsCadenaReal:[],
            filters:{
                canales:null,
                canalesReal:null,
                cadenas:null,
                cadenasReal:null,
                anio:null,
                semana:null,
            },
            category:'',
            oculta:false,
            varsi:[],
            chartOptions: createChartFaltantes(this),
            tableDataPtos:[],
            initReport:true
        }
    },
    mounted() {
        //this.getCanales();
        //this.getCadena();
        //this.getReportDetalle();
        this.changeAnio();
    },
    methods:{
        changeSemana(){
            this.filters.semana = this.$refs.semanaSelect.selectedSemana.value;
        },
        changeAnio(){let $this = this;
            this.filters.anio = this.$refs.anioSelect.selectedAnios.value;
            this.$refs.semanaSelect.anio =  this.filters.anio;
            this.$refs.semanaSelect.getDataSemanas();
            if (this.initReport){
                setTimeout(function(){
                    $this.getReportDetalle();
                }, 1000);
                this.initReport=false;
            }

        },
        changeCanal(){
            var canal = this.$refs.selectCanal.selectedCanales;
            var selectTodas = false;
            if (canal.length>0){
                canal.forEach(function (value,key){
                    if (canal[key].value === 0){
                        selectTodas = true;
                    }
                });
                var cadena = this.$refs.selectCadena;
                if (selectTodas){
                    cadena.canal = this.$refs.selectCanal.optionsCanales;
                    this.filters.canales = this.$refs.selectCanal.optionsCanales;
                    cadena.getCadenas();
                }
                else{
                    cadena.canal = canal;
                    this.filters.canales = canal;
                    cadena.getCadenas();
                }
            }
            else{
                this.filters.canales = null;
            }
        },
        changeCadena(){
            var cadenas = this.$refs.selectCadena.selectedCadenas;
            var selectTodas = false;
            if (cadenas.length>0){
                cadenas.forEach(function (value,key){
                    if (cadenas[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.cadenas = this.$refs.selectCadena.optionsCadenas;
                }
                else{
                    this.filters.cadenas = cadenas;
                }
            }
            else{
                this.filters.cadenas = null;
            }
        },
        /*
        getCanales(){let $this = this;
            axios.post('/api/AdminCatalogos/canales')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsCanales = r.data.Data; $this.optionsCanalesReal = r.data.Data;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        getCadena(){let $this = this;
            axios.post('/api/AdminCatalogos/cadenas')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsCadena = r.data.Data;$this.optionsCadenaReal = r.data.Data;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },*/
        SelectCanal(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.canales = [];
                    $this.filters.canales.push({
                        "name":'TODOS',
                        "value":0,
                    })
                }else{
                    $this.filters.canalesReal = $this.filters.canales;
                }
            });
            if (selectedOption.length === 0){ $this.filters.canalesReal = $this.filters.canales; $this.optionsCadenaReal = $this.optionsCadena; }
            else{$this.optionsCadenaReal = [];}
            $this.filters.canales.forEach(function (value,key){
                $this.optionsCadena.forEach(function (valueIn,keyIn){
                    if (value.value === 0){
                        $this.selectTodosCanales = true;
                        $this.filters.canalesReal = $this.optionsCanalesReal;
                        $this.optionsCadenaReal = $this.optionsCadena;
                        return;
                    }
                    if (parseInt(value.value) === parseInt(valueIn.canal)){
                        $this.selectTodosCanales = false;
                        $this.optionsCadenaReal.push({name: '' + valueIn.name+ '', value:valueIn.value, canal:''+valueIn.canal+''});
                    }
                });
            });
        },
        SelectCadena(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.cadenas = [];
                    $this.filters.cadenas.push({
                        "name":'TODAS',
                        "value":0,
                        "canal":0,
                    })
                }else{
                    $this.filters.cadenasReal = $this.filters.cadenas;
                }
            });
            if (selectedOption.length === 0){ $this.filters.cadenasReal = $this.filters.cadenas;}
            this.filters.cadenas.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodasCadenas = true;
                    $this.filters.cadenasReal = $this.optionsCadenaReal;
                }
            });
        },
        getReportDetalle(){ let $this = this;

            this.showLoad = true;

                axios.post('/api/Dashboard/faltantesDetalleOchenta',{semana: 10,anio:this.filters.anio,canal:this.filters.canales,cadena:this.filters.cadenas})
                    .then(r => {
                            if (r.data.Success){
                                $this.showLoad = false;
                                var dat = r.data.Data;
                                var datos = r.data.Result;
                                dat.forEach(function (value,key){
                                    $this.chartOptions.xAxis.categories.push(value.Categoria);
                                });
                                $this.chartOptions.series=[];
                                datos.forEach(function (value,key){
                                    //console.log(''+value+'');
                                    $this.chartOptions.series.push(JSON.parse(''+value+''));
                                });

                            }
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                    );


        }

    }
}
</script>

<style scoped>
.mx-datepicker{
    width: 80px!important;
}
</style>
