<template>
    <div class="border">
        <br><br>
        <div class="text-center">
            <h2><span class="text-primary">FALTANTES</span></h2>
        </div>
        <br>
        <vue-tabs active-tab-color="#e84611"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">
            <v-tab title="REPORTE - POR DETALLE"><br>
                <detalle-faltantes></detalle-faltantes>
            </v-tab>

            <v-tab title="REPORTE - POR CADENA 80/20"><br>
                <ochenta-faltantes></ochenta-faltantes>
            </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
import detalleFaltantes from '../Faltantes/faltantesDetalleNew';
import ochentaFaltantes from '../Faltantes/faltantesOchenta';
export default {
    components:{
        "detalle-faltantes": detalleFaltantes,
        "ochenta-faltantes": ochentaFaltantes,
    },
    name: "tabsFaltantes"
}
</script>

<style scoped>

</style>
