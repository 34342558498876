<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filtrosreporterutas-component ref="filtersReport"></filtrosreporterutas-component>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReport">Generar Reporte </button>
                    </div>
                    <div class="col-md-4" v-if="showResult">
                        <div v-if="loadfile">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando archivo, espere...</p>
                        </div>
                        <button v-if="!loadfile"  class="btn btn-primary" type="button" @click="ExportFile"><i class="fe fe-download-cloud"></i><b> Descargar Layout</b></button>
                    </div>
                </div><br>
                <div class="row" v-if="loadingGetData">
                    <div class="col-md-12 text-center">
                        <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                        <p><b>Consultando información, por favor espere!</b></p>
                    </div>
                </div><br>
                <div class="row" v-show="showResult">
                    <div class="col-md-12 text-center">
                        <v-client-table :data="tableDataVentas" :columns="columnsVentas" :options="optionsVentas" class="table-responsive" ref="tableProductos">
                            <div slot="Producto" slot-scope="props">
                                <span v-html="props.row.Producto"></span>
                            </div>
                            <div slot="Fecha" slot-scope="props">
                                <span v-html="props.row.Fecha"></span>
                            </div>
                            <div slot="Gestor" slot-scope="props">
                                <span v-html="props.row.Gestor"></span>
                            </div>
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "reporteVentas",
    data(){
        return{
            loadfile:false,
            showResult:false,
            loadingGetData: false,
            showData: false,
            columnsVentas: ['Id','Gestor','Tienda','Area','Formato','Canal','Cadena','Producto','Fecha','Cantidad','Comentario'],
            tableDataVentas: [],
            optionsVentas: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Gestor','Tienda','Area','Formato','Canal','Cadena','Producto'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Cantidad: 'Piezas',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Cantidad: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    mounted() {
    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        getReport(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else{
                this.loadingGetData = true;
                this.showData = false;
                this.showResult = false;
                this.tableDataVentas = [];
                axios.post('/api/Ventas/reportes',{data:filters})
                .then(r => {
                        $this.loadingGetData = false;
                        $this.showData = true;
                        $this.tableDataVentas = r.data.Data;
                        $this.showResult = true;
                    },
                    error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                }
                );
            }
        },
        ExportFile(){let $this = this;
            this.loadfile = true;
            var filters = this.$refs.filtersReport.filters;
            axios({
                method: 'post',
                url: '/api/Ventas/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {data: filters}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'VentasPoss.xlsx'
                    link.click()
                    $this.loadfile=false;
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
        },
    },
}
</script>

<style scoped>

</style>
