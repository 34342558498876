<template>
    <div>
        <vue-tabs active-tab-color="#e84611"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="TIENDAS">
                <br>
                <tiendas-component></tiendas-component>
            </v-tab>

            <v-tab title="FORMATOS - CADENAS">
                <br>
                <cancadfmt-component></cancadfmt-component>
            </v-tab>

            <v-tab title="ESTADOS - CIUDADES">
                <br>
                <ciudades-component></ciudades-component>
            </v-tab>

        </vue-tabs>
    </div>
</template>

<script>
import tableTienda from "./tiendas"
import tableFormatos from "./canalcadenaformato"
import tableCiudades from "./canalcadenaformato"
export default {
    name: "admtiendas",
    components:{
        "tiendas-component": tableTienda,
        "cancadfmt-component": tableFormatos,
        "ciudades-component": tableCiudades,
    }
}
</script>

<style scoped>

</style>
