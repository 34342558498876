<template>
    <div>
        <encuestas-contenedor></encuestas-contenedor>
        <semana-exhibiciones></semana-exhibiciones>

        <tabs-faltantes></tabs-faltantes>
        <reporte-eficiencia></reporte-eficiencia>
        <br><br><br>
    </div>
</template>

<script>
import semanaExhibiciones from '../Dashboard/ExhibicionesSemana/reporteExhibicionesSemana';
import tabFaltantes from '../Dashboard/Faltantes/tabsFaltantes';
import reporteEficiencia from '../Dashboard/Eficiencia/general';
export default {
    components:{
      "semana-exhibiciones": semanaExhibiciones,
      "tabs-faltantes": tabFaltantes,
      "reporte-eficiencia": reporteEficiencia,
    },
    name: "contenedor"
}
</script>

<style scoped>

</style>
