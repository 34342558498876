<template>
    <div>
        <multiselect v-model="agencias" :options="optionsAgencias" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectAgencia" ref="selectAgencia">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodasAgencias ? 'Todas' : values.length > 1 ? values.length + ' agencias' : values.length + ' agencia' }}</span></template>
        </multiselect>
    </div>
</template>

<script>
export default {
    name: "agencias",
    data(){
        return{
            agencias:[],
            agenciasReal:null,
            selectTodasAgencias:false,
            optionsAgencias:[],
            optionsAgenciasReal:[],
        }
    },
    mounted() {
        this.getAgencias();
    },
    methods:{
        getAgencias(){let $this = this;
            axios.post('/api/AdminCatalogos/getAgencias')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsAgencias = r.data.Data;
                            $this.optionsAgenciasReal = r.data.Data;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',6000,'center');}
                );
        },
        SelectAgencia(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.agencias = [];
                    $this.agencias.push({
                        "name":'TODAS',
                        "value":0,
                    })
                }else{
                    $this.agenciasReal = $this.agencias;
                }
            });
            if (selectedOption.length === 0){ $this.agenciasReal = $this.agencias; }
            else{$this.optionsAreasReal = [];}

        },
    }
}
</script>

<style scoped>

</style>
