<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row" >
            <div class="col-md-2">
                <filters-general ref="filtersReport"></filters-general>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button class="btn ripple btn-primary" @click="getReport">Generar Reporte </button>
                    </div>
                </div><br>
                <vue-tabs active-tab-color="#9a9e0c" active-text-color="white">
                    <v-tab title="Reporte" icon="fab fa-codiepie"><br>
                        <div class="text-center">
                            <div class="row" v-if="loadingGetData">
                                <div class="col-md-12 text-center">
                                    <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                                    <p><b>Consultando información, por favor espere!</b></p>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="showData">
                            <div class="col-md-4">
                                <table class="table table-striped table-hover ">
                                    <tbody>
                                    <tr>
                                        <th>Total:</th>
                                        <td class="text-center">{{ total }}</td>
                                        <td class="text-center">100 %</td>
                                    </tr>
                                    <tr>
                                        <th>Visitadas:</th>
                                        <td class="text-center">{{ visitadas }}</td>
                                        <td class="text-center">{{ total === 0 ? 0 : ((visitadas*100)/total).toFixed(2) }}</td>
                                    </tr>
                                    <tr>
                                        <th>En Proceso:</th>
                                        <td class="text-center">{{ proceso }}</td>
                                        <td class="text-center">{{ total === 0 ? 0 : ((proceso*100)/total).toFixed(2) }}</td>
                                    </tr>
                                    <tr>
                                        <th><b class="text-danger">No Visitadas:</b></th>
                                        <td class="text-center">{{ novisitadas }}</td>
                                        <td class="text-center">{{ total === 0 ? 0 : ((novisitadas*100)/total).toFixed(2) }}</td>
                                    </tr>
                                    <tr>
                                        <th>Pendientes:</th>
                                        <td class="text-center">{{ pendientes }}</td>
                                        <td class="text-center">{{ total === 0 ? 0 : ((pendientes*100)/total).toFixed(2) }}</td>
                                    </tr>

                                    <tr>
                                        <th>Canceladas:</th>
                                        <td class="text-center">{{ canceladas }}</td>
                                        <td class="text-center"><b>-</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-8">
                                <highcharts :options="chartOptions"></highcharts>
                            </div>
                        </div>
                    </v-tab>
                    <v-tab title="Tabla" icon="fas fa-table"><br>
                        <div class="row" v-if="loadingGetData">
                            <div class="col-md-12 text-center">
                                <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                                <p><b>Consultando información, por favor espere!</b></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12" v-show="showData">
                                <div class="pull-right">
                                    <button class="btn ripple btn-success pull-right" @click="getReportExcel">Generar Excel </button>
                                </div>
                                <v-client-table :data="tableData" :columns="columns" :options="options" class="table-responsive">
                                    <div slot="Gestor" slot-scope="props">
                                        <span v-html="props.row.Gestor"></span><br>
                                        <a target="blank" :href="'https://www.google.com/maps/search/?api=1&query='+props.row.Lat+','+props.row.Lon"><i class="fa fa-globe"></i></a>
                                    </div>
                                </v-client-table>
                            </div>
                        </div>
                    </v-tab>
                </vue-tabs>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "report",
    data(){
        return{
            loadingGetData: false,
            showData: false,
            total: null,
            visitadas: null,
            novisitadas: null,
            proceso: null,
            pendientes: null,
            canceladas: null,
            chartOptions: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            style: {
                                textOverflow: 'clip',fontSize:'14px',textShadow: false,textOutline: 0
                            },
                            format: '<span style="color:#000000">{point.name}: {point.percentage:.1f} %</span>',
                        }
                    }
                },
                credits: {
                    text: 'GRUPO PROMOCIONES - POSS',
                    href: 'http://www.grupopromociones.com'
                },

                series: [{
                    name: 'Porcentaje',
                    colorByPoint: true,
                    data: [{
                            name: 'Visitadas',
                            y: 0,
                            color: '#168a09'
                        },
                        {
                            name: 'En Proceso',
                            y: 0,
                            color: '#f5dc60'
                        },
                        {
                            name: 'No Visitadas',
                            y: 0,
                            color: '#1171a4'
                        },
                        {
                            name: 'Pendientes',
                            y: 0,
                            color: '#3C3F41'
                        },
                        {
                            name: 'Canceladas',
                            y: 0,
                            color: '#ED5565'
                        }
                        ]
                    }]
            },
            columns: ['Cveentrut', 'Fecha','Entrada','Salida','Rol','Gestor','Agencia','Area','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Stt','Tiempo','Cmt'],
            tableData: [],
            options: {
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10,15,25,45,50],
                filterable: ['Gestor','Stt','Kcmid','Rol','Fecha'],
                sortable: ['Gestor','Ciudad','Rol','Fecha','Kcmid'],
                listColumns: {Agencia: [], Stt: [{id:'Visitada',text:'Visitada'},{id:'Pendiente',text:'Pendiente'},{id:'Cancelada',text:'Cancelada'},{id:'En Proceso',text:'En Proceso'}]},
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                headings: {
                    Cveentrut: '#',
                    Fecha: 'Fecha Ruta',
                    Stt: 'Estatus',
                    Cmt: 'Comentarios Entrada',
                    Kcmid: 'Id Tienda',
                },
                columnsClasses: {
                    Cveentrut: 'text-center',
                    Area: 'text-center',
                    Estado: 'text-center',
                    Agencia: 'text-center',
                    Kcmid: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    mounted() {
        this.$refs.filtersReport.shows.departamentos = false;
        this.$refs.filtersReport.shows.categorias = false;
        this.$refs.filtersReport.shows.fabricantes = false;
        this.$refs.filtersReport.shows.marcas = false;
        this.$refs.filtersReport.shows.productos = false;
        this.$refs.filtersReport.shows.trades = false;
    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        getReport(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{
                this.loadingGetData = true;
                this.showData = false;
                axios.post('/api/ReporteRutas/getData',{data:filters})
                    .then(r => {
                            $this.loadingGetData = false;
                            $this.showData = true;
                            $this.total = r.data.Total;
                            $this.visitadas = r.data.Visitadas;
                            $this.proceso = r.data.En_proceso;
                            $this.novisitadas = r.data.No_visitada;
                            $this.pendientes = r.data.Pendientes;
                            $this.canceladas = r.data.Cancelada;
                            $this.tableData = r.data.Data;
                            $this.chartOptions.series[0].data[0].y = $this.total === 0 ? 0 : parseFloat((($this.visitadas*100)/$this.total).toFixed(2));
                            $this.chartOptions.series[0].data[1].y = $this.total === 0 ? 0 : parseFloat((($this.proceso*100)/$this.total).toFixed(2));
                            $this.chartOptions.series[0].data[2].y = $this.total === 0 ? 0 : parseFloat((($this.novisitadas*100)/$this.total).toFixed(2));
                            $this.chartOptions.series[0].data[3].y = $this.total === 0 ? 0 : parseFloat((($this.pendientes*100)/$this.total).toFixed(2));
                            $this.chartOptions.series[0].data[4].y = $this.total === 0 ? 0 : parseFloat((($this.canceladas*100)/$this.total).toFixed(2));
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                    );
            }
        },
        getReportExcel(){let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }
            else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }
            else{
                this.showData = false;
                axios({
                    method: 'post',
                    url: '/api/ReporteRutas/getDataExcel',
                    responseType: 'arraybuffer',
                    data: {data: this.tableData}}).then(r => {
                        let blob = new Blob([r.data], { type: 'application/xlsx' })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'ReporteRutasPoss.xlsx'
                        link.click()
                        $this.showData=true;
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
            }
        }
    }
}
</script>

<style scoped>

</style>
