<template>
    <div>
        <v-select :multiple="false" :options="optionsSemanas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedSemana" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "selectAnioSemanaF",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedSemana:null,
            anio:2021,
            optionsSemanas:[]
        }
    },
    mounted() {

    },
    methods:{
        getDataSemanas(){
            let $this = this;
            axios.post('/api/Dashboard/getSemanasF',{anio:this.anio})
                .then(r => {
                        $this.optionsSemanas = r.data.Data;
                        $this.selectedSemana = $this.optionsSemanas[$this.optionsSemanas.length-1];
                        this.$emit('cambioSemana');
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setCambio(){
            this.$emit('cambioSemana');
        }
    }
}
</script>

<style scoped>

</style>
