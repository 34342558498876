<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row" >
            <div class="col-md-2">
                <filters-general ref="filtersReport"></filters-general>
            </div>

            <div class="col-md-10">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "semaforo",
    data(){
        return{}
    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        getReport(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                $this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                $this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{


            }
        },
    }
}
</script>

<style scoped>

</style>
