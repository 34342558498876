<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import Highcharts from "highcharts";
function createChartRespuestas(vm){
    return{
        chart: {
            type: 'spline',
            zoomType:'x'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: []
        },
        yAxis: {
            title: {
                text: 'EXHIBICIONES'
            },
            labels: {
                formatter: function () {
                    return this.value;
                }
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true
        },
        plotOptions: {
            spline: {
                marker: {
                    radius: 4,
                    lineColor: '#666666',
                    lineWidth: 1
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: []
    }
}
export default {
    name: "chartShare",
    props:["metaData"],
    data(){
        return{
            chartOptions: createChartRespuestas(this),
        }
    },
    methods:{
        showGraph(){ let $this = this;
            this.chartOptions.xAxis.categories = [];
            this.metaData[0].forEach(function (key,value){
                $this.chartOptions.xAxis.categories = key.Semanas;
                $this.chartOptions.series.push(
                    {
                        name: key.Fabricante,
                        data: key.DataMostrar
                    }
                );

            });
        }
    },
    beforeMount() {
        this.showGraph();
    }
}
</script>

<style scoped>

</style>
