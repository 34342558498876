<template>
    <div>
        <v-select :multiple="true" :options="optionsGestores" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedGestores" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "promotores",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedGestores:[],
            optionsGestores:[]
        }
    },
    mounted() {
        this.getGestores();
    },
    methods:{
        async getGestores(){
            let response = await this.$peticionesApi('/api/AdminCatalogos/supervisores',);
            if  (response != null){
                this.optionsGestores = response;
            }
        },
        setCambio(){
            this.$emit('cambioPromotor');
        }
    }
}
</script>

<style scoped>

</style>
