<template>
    <div>
        <label >Encuesta</label><br>
        <v-select :multiple="false" :options="optionsEncuestas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedEncuestas" @input="setCambio" :disabled="disabledr"
                  :autoscroll="true"></v-select>
        <div class="row">
            <div class="col-md-12 text-center">
                <div v-if="disabledr" class="text-center">
                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                    <p>Preparando información, espere...</p>
                </div>
                <h3>{{fi}} - {{ff}}</h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "simpleEncuestas",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedEncuestas:null,
            optionsEncuestas:[],
            disabledr:false,
            fi:'',
            ff:'',
        }
    },
    mounted() {
        this.getEncuestas();
    },
    methods:{
        setCambio(){
            this.$emit('cambioEncuesta');
            this.fi = this.selectedEncuestas.ini;
            this.ff = this.selectedEncuestas.fin;
        },
        async getEncuestas(){
            this.optionsEncuestas = []; this.disabledr=true;
            let r = await this.$peticionesApi('/api/AdminCatalogos/getEncuestasFiltroEvaluacion');
            if (r != null){
                this.optionsEncuestas = r.encuestas;
            }this.disabledr=false;
        },
    }
}
</script>

<style scoped>

</style>
