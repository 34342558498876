<template>
    <div>
        <v-select :multiple="false" :options="optionsTipoEncuestas" :placeholder="'SELECCIONA '+placeholderm"
                  v-model="selectedTipoEncuesta" @input="setCambio" :disabled="disablE"></v-select>
    </div>
</template>

<script>
export default {
    name: "tipoEncuestas",
    props:{
        placeholderm:String,
        todaOpciones:{required:true,type:Boolean,default:false},
        disablE:{required:false,type:Boolean,default:false},
    },
    data(){
        return{
            selectedTipoEncuesta: null,
            optionsTipoEncuestas:[],
            agencia:[]
        }
    },
    mounted() {
        if (this.todaOpciones){
           this. optionsTipoEncuestas = [{label:"TODAS",value:'TODAS'}, {label:"ESTELARES",value:'ESTELARES'},{label:"FOCALIZADAS",value:'FOCALIZADAS'},
            {label:"VISIBILIDAD",value:'VISIBILIDAD'},{label:"RENTA DE ESPACIOS",value:'RENTA DE ESPACIOS'},{label:"GDM (GUÍAS DE MERCADEO)",value:'GDM (GUÍAS DE MERCADEO)'}]
            this.selectedTipoEncuesta = {label:"TODAS",value:'TODAS'};
        }
        else{
            this. optionsTipoEncuestas = [ {label:"ESTELARES",value:'ESTELARES'},{label:"FOCALIZADAS",value:'FOCALIZADAS'},
                {label:"VISIBILIDAD",value:'VISIBILIDAD'},{label:"RENTA DE ESPACIOS",value:'RENTA DE ESPACIOS'},{label:"GDM (GUÍAS DE MERCADEO)",value:'GDM (GUÍAS DE MERCADEO)'}]
        }
    },
    methods:{
        setCambio(){
            this.$emit('cambioTipoEncuesta');
        }

    }
}
</script>

<style scoped>

</style>
