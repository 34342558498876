<template>
    <div>
        <vue-tabs active-tab-color="#e84611"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="PRODUCTOS POSS">
                <br>
                <productostable-component></productostable-component>
            </v-tab>

            <v-tab title="CATALOGOS PRODUCTOS">
                <br>
                <catalogospto-component></catalogospto-component>
            </v-tab>

        </vue-tabs>

    </div>
</template>

<script>
export default {
    name: "productos",

}
</script>

<style scoped>

</style>
