<template>
    <div>
        <vue-tabs active-tab-color="#9a9e0c" active-text-color="white">
            <v-tab title="Importar Rutero" icon="fas fa-file-txt"><br>
                <importar-rutero></importar-rutero>
            </v-tab>
            <v-tab title="Actualizar Rutero" icon="fas fa-refresh"><br>
                <actualizar-rutero></actualizar-rutero>
            </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
export default {
    name: "contenedor",

}
</script>

<style scoped>

</style>
