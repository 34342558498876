<template>
    <div>
        <h2 class="text-info">Datos De Tiendas</h2>
        <div class="row">
            <div class="col-md-4">
                <label>Fecha Inicio:</label>
                <v-date-picker v-model='fechaInicio' color="orange" :attributes='attrs' @input="formattedDate(true)">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="bg-white border px-2 py-1 rounded form-control"
                            :value="inputValue"
                            v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-md-4">
                <label>Fecha Termino:</label>
                <v-date-picker v-model='fechaFin' color="orange" :attributes='attrs' @input="formattedDate(false)">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="bg-white border px-2 py-1 rounded form-control"
                            :value="inputValue"
                            v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-md-4 text-center">
                <button class="btn btn-success dim" type="button" style="margin-top: 0px;" data-toggle="modal" data-target="#myGrupos"><i class="fa fa-eye"></i> Ver Grupos</button>

            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Áreas: <b>{{optionsAreas.length-1}}/{{dataForm.areas.length}}</b></label>
                <v-select :multiple="true" :options="optionsAreas" :placeholder="'Selecciona Área'" v-model="dataForm.areas" @input="changeAarea"></v-select>
            </div>
            <div class="col-md-4">
                <label>Canales: <b>{{optionsCanales.length-1}}/{{dataForm.canales.length}}</b></label>
                <v-select :multiple="true" :options="optionsCanales" :placeholder="'Selecciona Canal'" v-model="dataForm.canales" @input="changeCanal"></v-select>
            </div>
            <div class="col-md-4">
                <label>Departamentos: <b>{{optionsDepartamentos.length-1}}/{{dataForm.departamentos.length!=0?1:0}}</b></label>
                <v-select :multiple="false" :options="optionsDepartamentos" :placeholder="'Selecciona Departamento'" v-model="dataForm.departamentos" @input="changeDepartamentos"></v-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Agencia: <b>{{optionsAgencia.length-1}}/{{dataForm.agencia.length}}</b></label>
                <v-select :multiple="true" :options="optionsAgencia" :placeholder="'Selecciona Agencia'" v-model="dataForm.agencia"></v-select>
            </div>
            <div class="col-md-4">
                <label>Cadenas: <b>{{optionsCadenas.length-1}}/{{dataForm.cadenas.length}}</b></label>
                <v-select :multiple="true" :options="optionsCadenas" :placeholder="'Selecciona Cadena'" v-model="dataForm.cadenas" @input="changeCadena"></v-select>
            </div>
            <div class="col-md-4">
                <label>Categorias: <b>{{optionsCategorias.length}}/{{dataForm.categorias.length!=0?1:0}}</b></label>
                <v-select :multiple="false" :options="optionsCategorias" :placeholder="'Selecciona Categoria'" v-model="dataForm.categorias" @input="changeCategoria"></v-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Estados: <b>{{optionsEstados.length-1}}/{{dataForm.estados.length}}</b></label>
                <v-select :multiple="true" :options="optionsEstados" :placeholder="'Selecciona Estados'" v-model="dataForm.estados" @input="changeEstados"></v-select>
            </div>
            <div class="col-md-4">
                <label>Formatos: <b>{{optionsFormatos.length-1}}/{{dataForm.formatos.length}}</b></label>
                <v-select :multiple="true" :options="optionsFormatos" :placeholder="'Selecciona Formato'" v-model="dataForm.formatos" @input="changeFormato"></v-select>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Ciudades: <b>{{optionsCiudades.length-1}}/{{dataForm.ciudades.length}}</b></label>
                <v-select :multiple="true" :options="optionsCiudades" :placeholder="'Selecciona Ciudad'" v-model="dataForm.ciudades" @input="changeCiudades"></v-select>
            </div>

        </div><br>
        <div class="row">
            <div class="col-md-4">
                <label>Promoción: </label>
                <v-select :multiple="false" :options="optionsPromociones" :placeholder="'Selecciona Promoción'" v-model="dataForm.promocion"></v-select>
                <label>Tipo Respuesta:</label>
                <v-select :multiple="false" :options="optionsRespuestas" :placeholder="'Selecciona Respuesta'" v-model="dataForm.respuesta"></v-select>
                <label>Pregunta:</label>
                <textarea class="form-control" rows="4" maxlength="200" v-model="dataForm.pregunta"></textarea>    <br>
                <div v-if="!enviarInformacion">
                    <button class="btn btn-info btn-block" type="button" @click="downLoadFile"><b>Descargar Layout Encuesta <i class="fa fa-file-excel-o"></i></b></button>
                    <!--!<button class="btn btn-primary btn-block" type="button" @click="downLoadFile"><b>Registrar Encuesta <i class="fa fa-edit"></i></b></button>-->
                    <button class="btn btn-warning btn-block" type="button" @click="LimpiarForm"><b>Limpiar</b></button>
                </div>
                <div class="row" v-else>
                    <div class="col-md-12 text-center">
                        <div class="spiner-example" >
                            <div class="sk-spinner sk-spinner-wave">
                                <div class="sk-rect1"></div>
                                <div class="sk-rect2"></div>
                                <div class="sk-rect3"></div>
                                <div class="sk-rect4"></div>
                                <div class="sk-rect5"></div>
                            </div>
                            <p><b>Por favor espere. Estamos trabajando su solicitud!</b></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-4">
                        <div class="text-center">
                            <h4>Total Tiendas: <b>{{tableData.length}}</b></h4>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-info dim" type="button" style="margin-top: -30px;" @click="SaveGrupo"><i class="fa fa-check"></i> Guardar Grupo</button>
                    </div>
                    <div class="col-md-4"></div>
                </div>
                <v-client-table :data="tableData" :columns="columns" :options="options" class="table-responsive">
                    <div slot="Accion" slot-scope="props" >
                        <h3><i class="fa fa-times" style="cursor:pointer;color: darkred" @click="removeForId(props.row.Id)"></i></h3>
                    </div>
                </v-client-table>
            </div>
        </div>
        <div class="row" v-if="false">
            <div class="col-md-4">
                <label>Imagen:</label>
                <input type="file" name="myImage" accept="image/png"  class="form-control" @change="handleImage"/>
            </div>
            <i class="fa fa-eye" style="color: #0e9aef;cursor:pointer" @click="imgvisible = !imgvisible" v-show="!imgvisible"> Mostrar Imagen</i>
            <div class="col-md-4" v-show="dataForm.imagen!='' & imgvisible">
                <i class="fa fa-eye" style="color: darkred;cursor:pointer" @click="imgvisible = !imgvisible"> Ocultar imagen</i>
                <img :src="dataForm.imagen" class="img img-responsive" alt="ImagenPrioridad" />
            </div>
        </div>
        <div class="modal inmodal" id="myGrupos" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated fadeIn">
                    <div class="modal-header">
                        <h4 class="modal-title">Grupos Registrados</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <ul id="example-1" class="v-list" style="height: 300px;overflow-y: auto;">
                                    <li v-for="item in grupos">
                                        {{ item.Id }} -> {{item.Comentario}} <span class="badge badge-primary" style="cursor:pointer" @click="setValues(item.Id)"> Aplicar!</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "crearEncuesta",
    data() {
        return{
            edicion:0,
            claveEdit:0,
            enviarInformacion:false,
            message: 'Hola Mundo',
            info: null,
            imgvisible:true,
            optionsAreas: [],
            optionsCategoriasCampana: [],
            optionsAgencia: [],
            optionsEstados: [],
            optionsCiudades: [],
            optionsCanales: [],
            optionsCadenas: [],
            optionsFormatos: [],
            optionsTiendas: [],
            optionsDepartamentos: [],
            optionsCategorias: [],
            optionsMarcas: [],
            optionsProductos: [],
            optionsPromociones: [],
            grupos: [],
            optionsRespuestas: [{value:1,label:"Si / No"},{value:2,label:"Número"}],
            fechaInicio: null,
            fechaFin: null,
            attrs: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date(),
                },
            ],
            dataForm:{
                respuestaCategorias: [],
                fechaInicio:null,
                fechaFin:null,
                areas: [],
                agencia:[],
                estados:[],
                ciudades:[],
                canales:[],
                cadenas:[],
                formatos:[],
                tiendas:[],
                departamentos:[],
                categorias:[],
                marcas:[],
                productos:[],
                promocion:[],
                respuesta:[],
                pregunta:"",
                comentario:"",
                imagen:'',
                type:0
            },
            columns: ['Id','KCMID','Tienda','Estado','Ciudad','Formato','Accion'],
            tableData: [],
            options: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:true },
                perPage:6,
                perPageValues: [6,25,45,50],
                filterable: ['Tienda','KCMID','Estado','Ciudad','Formato'],
                sortable: ['Formato','KCMID'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Tienda: 'Tienda',
                    Estado: 'Estado',
                    Ciudad: 'Ciudad',
                    Formato: 'Formato',
                    Accion: 'Acción',
                },
                columnsClasses: {
                    Id: 'text-center font-weight-bold',
                    Tienda: 'text-justify',
                    Estado: 'text-justify',
                    Respuesta: 'text-justify',
                    Ciudad: 'text-center',
                    Formato: 'text-center',
                    Accion: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        };
    },
    mounted() {
        this.getData();
    },
    methods:{
        getData(){
            this.optionsAreas=[],
            this.optionsAgencia=[],
            this.optionsEstados=[],
            this.optionsCiudades=[],
            this.optionsCanales=[],
            this.optionsCadenas=[],
            this.optionsFormatos=[],
            this.optionsTiendas=[],
            this.optionsDepartamentos=[],
            this.optionsCategorias=[],
            this.optionsMarcas=[],
            this.optionsProductos=[],
            this.optionsPromociones=[],
            this.dataForm.fechaInicio = null;
            this.dataForm.fechaFin = null;
            this.dataForm.areas= [];
            this.dataForm.agencia=[];
            this.dataForm.estados=[];
            this.dataForm.ciudades=[];
            this.dataForm.canales=[];
            this.dataForm.cadenas=[];
            this.dataForm.formatos=[];
            this.dataForm.tiendas=[];
            this.dataForm.type=0;
            this.dataForm.departamentos=[];
            this.dataForm.categorias=[];
            this.dataForm.marcas=[];
            this.dataForm.productos=[];
            this.dataForm.promocion=[];
            this.dataForm.respuesta=[];
            this.dataForm.respuestaCategorias=[];
            this.dataForm.pregunta="";
            this.dataForm.comentario="";
            this.dataForm.imagen='';
            this.fechaInicio = null;
            this.fechaFin = null;
            this.getAreas();
            this.getAgencia();
            this.getCanales();
            this.getPromociones();
            this.getDepartamentos();
            this.getGrupos();
        },
        setValues(id){
            var idx = 0;
            for (var i = 0; i < this.grupos.length; i++) {
                if (this.grupos[i].Id === id) {
                    idx= i;
                }
            }
            this.tableData = [];
            this.tableData = JSON.parse(this.grupos[idx].Tiendas);
            this.dataForm.areas = JSON.parse(this.grupos[idx].Areas);
            this.dataForm.agencia = JSON.parse(this.grupos[idx].Agencias);
            this.dataForm.estados = JSON.parse(this.grupos[idx].Estados);
            this.dataForm.ciudades = JSON.parse(this.grupos[idx].Ciudades);
            this.dataForm.canales = JSON.parse(this.grupos[idx].Canales);
            this.dataForm.cadenas = JSON.parse(this.grupos[idx].Cadenas);
            this.dataForm.formatos = JSON.parse(this.grupos[idx].Formatos);
        },
        getGrupos(){let $this = this;
            this.grupos = [];
            axios
                .post('/api/Encuestas/Peticiones',{combo:31})
                .then(function(response){
                    console.log(response.data);
                    $this.grupos = response.data;
                    console.log($this.grupos);
                } );

        },
        SaveGrupo(){
            if (this.tableData.length === 0){
                Swal.fire('Error!', 'Es necesario tener más de una tienda seleccionada, para registrar el grupo!', 'error');
            }else{
                Swal.fire({
                    icon:'question',
                    input: 'textarea',
                    title: 'Descripción del grupo!',
                    inputPlaceholder: 'Ingresa la descripción del grupo',
                    inputAttributes: {
                        'aria-label': 'Ingresa la descripción del grupo'
                    },
                    showCancelButton: true
                }).then((result) => {
                    console.log(result);
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        let $this = this;
                        if (result.value!==''){
                            axios
                                .post('/api/Encuestas/Peticiones',{combo:30,data:this.dataForm,tiendas:this.tableData,grupo:result.value})
                                .then(function (response){
                                    console.log(response);
                                    Swal.fire('Saved!', response.Mensaje, 'success');
                                    $this.getGrupos();
                                });
                        }else{
                            Swal.fire('Grupo No Guardado', 'Es necesario una descripción del grupo!', 'info');
                        }

                    } else if (result.isDismissed) {
                        Swal.fire('Grupo No Guardado', 'Acción cancelada', 'info');
                    }
                });
            }

        },
        getCategorias(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:25})
                .then(response => (this.optionsCategoriasCampana = response.data));
        },
        removeForId(id){
            for (var i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].Id === id) {
                    this.tableData.splice(i, 1);
                }
            }

        },
        formattedDate(i) {
            if (i){
                var mm = this.fechaInicio.getMonth() + 1; // getMonth() is zero-based
                var dd = this.fechaInicio.getDate();
                var fecha = [this.fechaInicio.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                ].join('-');
                this.dataForm.fechaInicio = fecha;
            }else{
                var mm = this.fechaFin.getMonth() + 1; // getMonth() is zero-based
                var dd = this.fechaFin.getDate();
                var fecha = [this.fechaFin.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                ].join('-');
                this.dataForm.fechaFin = fecha;
            }
        },
        getAreas(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:1})
                .then(response => (this.optionsAreas = response.data));
        },
        getAgencia(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:2})
                .then(response => (this.optionsAgencia = response.data));
        },
        getEstados(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:3})
                .then(response => (this.optionsEstados = response.data));
        },
        getCiudades(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:4})
                .then(response => (this.optionsCiudades = response.data));
        },
        getCanales(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:5})
                .then(response => (this.optionsCanales = response.data));
        },
        getCadenas(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:6})
                .then(response => (this.optionsCadenas = response.data));
        },
        getFormatos(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:7})
                .then(response => (this.optionsFormatos = response.data))
        },
        getTiendas(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:8})
                .then(response => (this.optionsTiendas = response.data));
        },
        getDepartamentos(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:9})
                .then(response => (this.optionsDepartamentos = response.data));
        },
        getPromociones(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:13})
                .then(response => (this.optionsPromociones = response.data));
        },
        changeAarea(){
            this.dataForm.estados = [];
            this.dataForm.ciudades = [];
            this.optionsEstados = [];
            this.optionsCiudades = [];
            axios
                .post('/api/Encuestas/Peticiones',{combo:3,areas:this.dataForm.areas})
                .then(response => (this.optionsEstados = response.data));
        },
        changeEstados(){
            this.dataForm.ciudades = [];
            this.optionsCiudades = [];
            axios
                .post('/api/Encuestas/Peticiones',{combo:4,estados:this.dataForm.estados})
                .then(response => (this.optionsCiudades = response.data));
        },
        changeCanal(){
            this.dataForm.cadenas = [];
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsCadenas = [];
            this.optionsFormatos = [];
            this.optionsTiendas = [];
            axios
                .post('/api/Encuestas/Peticiones',{combo:6,canales:this.dataForm.canales})
                .then(response => (this.optionsCadenas = response.data));

        },
        changeCadena(){
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsFormatos = [];
            this.optionsTiendas = [];
            axios
                .post('/api/Encuestas/Peticiones',{combo:7,cadenas:this.dataForm.cadenas})
                .then(response => (this.optionsFormatos = response.data))
        },
        changeCiudades(){
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsTiendas = [];
        },
        findById(id) {
            for (var i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].Id === id) {
                    return false;
                }
            }
            return true;
        },
        changeFormato(){
            if (this.dataForm.formatos.length>0){
                if (this.dataForm.ciudades.length<1){
                    this.dataForm.formatos = [];
                    this.$toastMessage("error","Por favor seleccione por lo menos una ciudad!, para traer las tiendas correspondientes a los formatos seleccionados!",4000,'center');
                }else{ let $this = this;
                    this.dataForm.tiendas = [];
                    this.optionsTiendas = [];
                    axios
                        .post('/api/Encuestas/Peticiones',{combo:27,formatos:this.dataForm.formatos,ciudades:this.dataForm.ciudades})
                        .then(function (r){
                            //console.log(r);
                            r.data.forEach(function (value){
                                //console.log(value.Id+ ' ' + value.Tienda+ ' -> '+key );
                                if ($this.findById(value.Id)){
                                    $this.tableData.push({Id:value.Id,KCMID:value.KCMID,Tienda:value.Tienda,Estado:value.Estado,Ciudad:value.Ciudad,Formato:value.Formato,Accion:''});
                                }
                            });
                            $this.optionsTiendas = r.data;
                        });
                }
            }else{
                this.optionsTiendas = [];
                this.dataForm.ciudades = [];
            }
        },
        changeDepartamentos(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:10,departamentos:this.dataForm.departamentos})
                .then(response => (this.optionsCategorias = response.data));
        },
        changeCategoria(){
            this.dataForm.marcas=[];
            this.optionsMarcas=[];
            axios
                .post('/api/Encuestas/Peticiones',{combo:11,categorias:this.dataForm.categorias,type:this.dataForm.type})
                .then(response => (this.optionsMarcas = response.data));
        },
        changeMarca(){
            axios
                .post('/api/Encuestas/Peticiones',{combo:12,marcas:this.dataForm.marcas,
                    categorias:this.dataForm.categorias})
                .then(response => (this.optionsProductos = response.data))
        },
        handleImage(e){let $this = this;
            const selectedImage = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function(event) {
                $this.dataForm.imagen = event.target.result;
            };
            reader.onerror = function(error) {
                alert(error);
            };
            reader.readAsDataURL(selectedImage);
        },
        downLoadFile() {
            if (this.dataForm.fechaInicio === null) {
                this.$toastMessage("error", "Por favor seleccione la fecha de inicio",3000,'center');
            }
            else if (this.dataForm.fechaFin === null) {
                this.$toastMessage("error", "Por favor seleccione la fecha de fin",3000,'center');
            }
            else if (this.dataForm.areas.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos un área",3000,'center');
            }
            else if (this.dataForm.agencia.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos una agencia",3000,'center');
            }
            else if (this.dataForm.estados.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos un estado",3000,'center');
            }
            else if (this.dataForm.ciudades.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos una ciudad",3000,'center');
            }
            else if (this.dataForm.canales.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos un canal",3000,'center');
            }
            else if (this.dataForm.cadenas.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos una cadena",3000,'center');
            }
            else if (this.dataForm.formatos.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos un formato",3000,'center');
            }
            else if (this.dataForm.categorias.length === 0) {
                this.$toastMessage("error", "Por favor seleccione por lo menos una categoria",3000,'center');
            }
            else if (this.dataForm.respuesta.length === 0) {
                this.$toastMessage("error", "Por favor seleccione el tipo de respuesta",3000,'center');
            }
            else if (this.dataForm.pregunta.length === 0 || this.dataForm.pregunta.length <= 10) {
                this.$toastMessage("error", "Por favor espeficique con más detalle su pregunta, para la encuesta",3000,'center');
            }
            else{ let $this = this;
                axios
                    .post('/api/Encuestas/Peticiones',{
                            combo:28,
                            tiendas:this.tableData,
                            categoria:this.dataForm.categorias,
                            pregunta:this.dataForm.pregunta,
                            promocion:this.dataForm.promocion,
                            respuesta:this.dataForm.respuesta,
                            fini:this.dataForm.fechaInicio,
                            ffin:this.dataForm.fechaFin,
                            img:this.dataForm.imagen
                        },
                        {responseType: 'blob'})
                    .then(function (response){
                        const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        }))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'PlantillaEncuestas.xlsx')
                        document.body.appendChild(link)
                        link.click()
                    }).
                catch(function (error) {
                    $this.$toastMessage("error",error,3000,'center');
                    $this.$toastMessage("error","Informe este error a IT!",3000,'center');
                    $this.enviarInformacion = false;
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
            }
        },
        LimpiarForm(){
            this.getData();
            if (this.edicion===1) this.edicion=0;
        },
        editar(clave){let $this = this;this.claveEdit=clave;this.edicion=1;
            console.log(clave);
            axios.post('/api/Encuestas/Peticiones',{combo:18,clave:clave})
                .then(function (r){
                    $this.$toastMessage("success",r.data.Mensaje,3000,'success');
                    console.log(r.data);
                    $this.dataForm.comentario = r.data.Prioridad.Comentario;
                    $this.dataForm.pregunta = r.data.Prioridad.Nomentprd;
                    $this.dataForm.respuesta = {'label':r.data.Prioridad.Tpo===1?'Si / No':'Número','value':r.data.Prioridad.Tpo};
                    $this.dataForm.promocion = {'label':r.data.Prioridad.Promocion,'value':r.data.Prioridad.Cveentpro};
                    $this.dataForm.imagen = '/poss'+r.data.Prioridad.Pathimg;
                    $this.dataForm.fechaInicio = r.data.Prioridad.Finicio;
                    $this.fechaInicio = new Date(r.data.Prioridad.Finicio);
                    $this.dataForm.fechaFin = r.data.Prioridad.Ftermino;
                    $this.fechaFin = new Date(r.data.Prioridad.Ftermino);
                    $this.dataForm.type=r.data.Prioridad.Type;
                    var a = JSON.parse(r.data.Areas);
                    setTimeout(function(){
                        if (a.length === ($this.optionsAreas.length-1)){
                            $this.dataForm.areas = [$this.optionsAreas[0]];
                            $this.changeAarea();
                        }else{
                            $this.dataForm.areas = a;
                            $this.changeAarea();
                        }
                    }, 500);
                    var ag = JSON.parse(r.data.Agencias);
                    setTimeout(function(){
                        if (ag.length === ($this.optionsAgencia.length-1)){
                            $this.dataForm.agencia = [$this.optionsAgencia[0]];
                        }else{
                            $this.dataForm.agencia = ag;
                        }
                    }, 600);
                    var e = JSON.parse(r.data.Estados);
                    setTimeout(function(){
                        if (e.length === ($this.optionsEstados.length-1)){
                            $this.dataForm.estados = [$this.optionsEstados[0]];
                            $this.changeEstados();
                        }else{
                            $this.dataForm.estados = e;
                            $this.changeEstados();
                        }
                    }, 1000);
                    var can = JSON.parse(r.data.Canales);
                    setTimeout(function(){
                        if (can.length === ($this.optionsCanales.length-1)){
                            $this.dataForm.canales = [$this.optionsCanales[0]];
                            $this.changeCanal();
                        }else{
                            $this.dataForm.canales = can;
                            $this.changeCanal();
                        }
                    }, 1000);
                    var cad = JSON.parse(r.data.Cadenas);
                    setTimeout(function(){
                        if (cad.length === ($this.optionsCadenas.length-1)){
                            $this.dataForm.cadenas = [$this.optionsCadenas[0]];
                            $this.changeCadena();
                        }else{
                            $this.dataForm.cadenas = cad;
                            $this.changeCadena();
                        }
                    }, 1000);

                    var c = JSON.parse(r.data.Ciudades);
                    setTimeout(function(){
                        if (c.length === ($this.optionsCiudades.length-1)){
                            $this.dataForm.ciudades = [$this.optionsCiudades[0]];
                        }else{
                            $this.dataForm.ciudades = c;

                        }
                    }, 1500);
                    var f = JSON.parse(r.data.Formatos);
                    setTimeout(function(){
                        if (f.length === ($this.optionsFormatos.length-1)){
                            $this.dataForm.formatos = [$this.optionsFormatos[0]];
                        }else{
                            $this.dataForm.formatos = f;
                        }
                    }, 2000);
                    setTimeout(function(){$this.changeFormato();},2300);
                    var tda = JSON.parse(r.data.Tiendas);
                    setTimeout(function(){
                        if (tda.length === ($this.optionsTiendas.length-1)){
                            $this.dataForm.tiendas = [$this.optionsTiendas[0]];
                        }else{
                            $this.dataForm.tiendas = tda;
                        }
                    }, 3000);

                    $this.dataForm.departamentos = r.data.Departamentos;
                    $this.changeDepartamentos();
                    setTimeout(function(){
                        $this.dataForm.categorias = r.data.Categorias;
                        $this.changeCategoria();
                    }, 3000);
                    var m = JSON.parse(r.data.Marcas);
                    setTimeout(function(){
                        if (tda.length === ($this.optionsMarcas.length-1)){
                            $this.dataForm.marcas = [$this.optionsMarcas[0]];

                        }else{
                            $this.dataForm.marcas = m;

                        }
                    }, 3000);
                    setTimeout(function(){$this.changeMarca(); }, 3100);
                    var p = JSON.parse(r.data.Productos);
                    setTimeout(function(){
                        if (p.length === ($this.optionsProductos.length-1)){
                            $this.dataForm.productos = [$this.optionsProductos[0]];
                        }else{
                            $this.dataForm.productos = p;
                        }
                    }, 3400);


                });
        },
        guardarData(){
            Swal.fire({
                title: '¿Registrar Campaña?',
                text: "Revise el contenido de la camapaña para confirmar su registro!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#308c0e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, registrar!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    let $this = this;
                    this.enviarInformacion = true;
                    axios
                        .post('/api/Encuestas/Peticiones',{combo:26,data:this.dataForm})
                        .then(function (r){
                            $this.$toastMessage("success",r.data.Mensaje,3000,'center')
                            $this.enviarInformacion = false;
                        }).
                    catch(function (error) {
                        $this.$toastMessage("error",error,3000,'center');
                        $this.$toastMessage("error","Informe este error a IT!",3000,'center');
                        $this.enviarInformacion = false;
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });

                }
            })
        },
        guardarDataEdit(){
            let $this = this;
            this.enviarInformacion = true;
            axios
                .post('/api/Encuestas/Peticiones',{combo:19,data:this.dataForm,clave:this.claveEdit})
                .then(function (r){
                    alertaInfo("Exito!",r.data.Mensaje)
                    $this.enviarInformacion = false;
                }).
            catch(function (error) {
                $this.$toastMessage("error",error,3000,'center');
                $this.$toastMessage("error","Informe este error a IT!",3000,'center');
                $this.enviarInformacion = !this.enviarInformacion;
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        }
    }

}
</script>

<style scoped>

</style>
