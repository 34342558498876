<template>
    <div>
        <v-select :multiple="false" :options="optionsAnios" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedAnios" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "selectAnioSemana",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedAnios:{label:"2022",value:2022},
            optionsAnios:[{label:"2021",value:2021},{label:"2022",value:2022},{label:"2023",value:2023}]
        }
    },
    mounted() {

    },
    methods:{
        setCambio(){
            this.$emit('cambioAnio');
        }
    }
}
</script>

<style scoped>

</style>
