<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filters-generales ref="filtersReport"></filters-generales>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12">
                        <simple-encuestas :placeholderm="'Prioridad'" ref="encuestaFiltro"></simple-encuestas>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button class="btn ripple btn-primary" @click="getData">Generar Reporte </button>
                        <br>
                        <div v-if="loadingGetData"><br>
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                        <br>
                    </div>
                </div><br>
                <div v-show="showResult">
                    <div class="example">
                        <div class="border">
                            <div class="bg-gray-300 nav-bg">
                                <nav class="nav nav-tabs">
                                    <a class="nav-link active" data-toggle="tab" href="#tabCont2">Galería <i class="fas fa-images"></i></a>
                                    <a class="nav-link" data-toggle="tab" href="#tabCont3">Tabla <i class="fas fa-table"></i></a>
                                </nav>
                            </div>
                            <div class="card-body tab-content">
                                <div class="tab-pane active show" id="tabCont2">
                                    <!-- Modal effects -->
                                    <div class="modal" id="modalShowData" data-backdrop="static">
                                        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                            <div class="modal-content modal-content-demo">
                                                <div class="modal-header">
                                                    <h6 class="modal-title">Detalle Imagen <b>Prioridad</b> <i class="fas fa-file-image"></i></h6>
                                                    <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row" v-if="dataGaleria.length>0">
                                                        <div class="col-md-6">
                                                            <div class="text-center">
                                                                zoomed: {{ zoomed }}
                                                                <button @click="$refs.zoomer.zoomIn()">+</button>
                                                                <button @click="$refs.zoomer.zoomOut()">-</button>
                                                            </div>
                                                            <v-zoomer ref="zoomer"
                                                                      style=" border: solid 1px silver;width: 350px; height: 500px;"
                                                                      :max-scale="10"
                                                                      :zooming-elastic="false"
                                                                      :zoomed.sync="zoomed">
                                                                <img
                                                                    :src="'/storage'+dataGaleria[idxGlobalSelected].Arch"
                                                                    style="object-fit: contain; width: 100%; height: 100%;"
                                                                    alt="imgZoom"
                                                                >
                                                            </v-zoomer>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label class="tx-bold">Código</label>
                                                            <p class="tx-bold">{{dataGaleria[idxGlobalSelected].Cdgentpto}}</p>
                                                            <label class="tx-bold">Producto</label>
                                                            <p class="tx-12">{{dataGaleria[idxGlobalSelected].Producto}}</p>
                                                            <label class="tx-bold">Fecha Registro</label>
                                                            <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Fchcrt}}</p>
                                                            <label class="tx-bold">Canal</label>
                                                            <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Canal}}</p>
                                                            <label class="tx-bold">Cadena</label>
                                                            <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Cadena}}</p>
                                                            <label class="tx-bold">Formato</label>
                                                            <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Formato}}</p>
                                                            <label class="tx-bold">Tienda</label>
                                                            <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Tienda}}</p>
                                                            <label class="tx-bold">Estado</label>
                                                            <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Estado}}</p>
                                                            <label class="tx-bold">Ciudad</label>
                                                            <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Ciudad}}</p>
                                                            <label class="tx-bold">Usuario</label>
                                                            <p class="tx-12 tx-bold text-info" v-html="dataGaleria[idxGlobalSelected].Usuario"></p>
                                                            <p class="tx-12 tx-bold text-danger" v-if="dataGaleria[idxGlobalSelected].Comentario!=='' && dataGaleria[idxGlobalSelected].Comentario!=='EJECUCIÓN INCORRECTA'">Cnt: {{dataGaleria[idxGlobalSelected].Comentario}}</p>
                                                            <p class="badge badge-danger" v-else>{{dataGaleria[idxGlobalSelected].Comentario}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Modal effects-->
                                    <div class="row">
                                        <div class="col-md-2"><h5><b class="text-success">PRIORIDAD</b></h5></div>
                                        <div class="col-md-4"><h5>Total Imagenes: <b class="text-success">{{totalImg}}</b></h5></div>
                                        <div class="col-md-6">
                                            <button v-show="selectAllBtn" class="btn ripple btn-success" @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Seleccionar Todo</button> &nbsp;&nbsp;&nbsp;
                                            <button v-show="!selectAllBtn" class="btn ripple btn-danger " @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Deseleccionar Todo</button> &nbsp;&nbsp;&nbsp;
                                            <button v-if="!loadfileZip" class="btn ripple btn-primary " @click="ExportFile" ><i class="fe fe-download-cloud"></i> Descargar Galeria</button> &nbsp;&nbsp;&nbsp;
                                            <button v-if="!loadFilePptx" class="btn ripple btn-secondary "  @click="ExportFilePptx"><i class="fas fa-file-powerpoint"></i> Descargar Power Point</button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4"></div>
                                        <div class="col-md-4">
                                            <div v-if="loadfileZip || loadFilePptx">
                                                <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                                <p>Preparando archivo, espere...</p>
                                            </div>
                                        </div>
                                        <div class="col-md-4"></div>
                                    </div>
                                    <br>
                                    <div id="global" class="row" v-if="dataGaleria.length>0">
                                        <div class="col-md-4" v-for="items in dataGaleria" :class="{'border border-danger'  : items.Comentario==='EJECUCIÓN INCORRECTA' }" v-if="items.ShowT">
                                            <div class="row" >
                                                <div class="col-md-1">
                                                    <label class="ckbox" v-if="items.Disponible === '1'">
                                                        <input type="checkbox" v-model="items.Checked">
                                                        <span> </span>
                                                    </label><br>
                                                </div>
                                                <div class="col-md-6">
                                                    <img v-show="items.Mostrar === '1'" class="img-thumbnail" :src="'/storage'+items.Arch" alt="imagenEncuestas" @load="onImgLoad(items.Id)" @error="setFail(items.Id)"/>
                                                    <img v-if="items.Mostrar === '0'" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                                                </div>
                                                <div class="col-md-5">
                                                    <p class="tx-bold">{{items.Kcmid}}
                                                    <p class="tx-12 tx-bold text-danger">{{items.Fchreg}}</p>
                                                    <p class="tx-12 tx-bold text-primary">{{items.Fcupd}}</p>
                                                    <p class="tx-12 tx-bold text-danger">{{items.Comentario}}</p>
                                                    <p class="tx-12 tx-bold">{{items.Pregunta}}</p>
                                                    <p class="badge badge-info">{{items.Respuesta}}</p>
                                                    <p class="tx-12 tx-bold text-info">{{items.Rol}}</p>
                                                    <p class="tx-12 tx-bold text-dark" >{{items.promotor}}</p>
                                                    <button @click="loadData(items.Id)" v-if="items.Disponible === '1'" class="btn ripple btn-outline-info" data-effect="effect-super-scaled" data-toggle="modal" href="#modalShowData"><i class="fas fa-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="text-center">
                                                <button class="btn ripple btn-info btn-with-icon" @click="mostrarveintemas"><i class="fas fa-question"></i> Mostrar siguientes 20</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tabCont3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="text-center">
                                                        <button class="btn ripple btn-success btn-with-icon" @click="getReportDataRespuestas"><i class="fas fa-file-excel"></i> Descargar Excel</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-client-table :data="tableDataRespuestas" :columns="columnsDataRespuestas" :options="optionsDataRespuestas" class="table-responsive" ref="tableEncuestas">
                                            </v-client-table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import filtrosGeneral from "../../../components/filters/filtrosGeneral";
import simpleEncuestas from "../../../components/generales/simpleEncuestas";
export default {
    name: "evaluacion",
    components:{
        'filters-generales':filtrosGeneral,
        'simple-encuestas':simpleEncuestas,
    },
    data(){
        return{
            loadingGetData:false,
            showResult:false,
            loadFilePptx:false,
            loadfileZip:false,
            dataGaleria:[],
            zoomed:false,
            selectAllBtn:true,
            isLoaded: false,
            totalImg:0,
            imagesS:40,
            idxGlobalSelected:0,
            tipoGaleria:'ENCUESTAS',
            columnsDataRespuestas: ['Id','Fecha','Pregunta','Agencia','Area','Estado','Ciudad','Gestor','Rol','Canal','Cadena','Formato','Tienda','Kcmid','Respuesta','Comentario','Editor','FechaUpdate'],
            tableDataRespuestas: [],
            optionsDataRespuestas: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Kcmid','Tienda','Area','Formato','Canal','Cadena','Estado','Ciudad','Gestor','Editor'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    FechaUpdate:'Fecha Edición'
                },
                columnsClasses: {
                    Id: 'text-center tx-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Editor: 'text-center text-info tx-bold',
                    FechaUpdate: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Fotografico: 'text-center',
                    Ventas: 'text-center',
                    Inventarios: 'text-center',
                    Comentario: 'text-center',
                    Encuestas: 'text-center',
                    Faltantes: 'text-center',
                    Exhibiciones: 'text-center',
                    Anaquel: 'text-center',
                    Promociones: 'text-center',
                    Comentarios: 'text-center',
                    Resurtidos: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    methods: {
        keyboardEvent(e) {
            if (e.which === 13) {
                this.getData();
            }
        },
        selectAll(type){
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                this.dataGaleria[z].Checked = type;
            }
            this.selectAllBtn = !this.selectAllBtn;
        },
        async getData(){
            var filters = this.$refs.filtersReport.filters;
            var enct = this.$refs.encuestaFiltro.selectedEncuestas;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }
            else if (enct === null){
                this.$toastMessage('error',"Seleccione una encuesta por favor!",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{
                this.loadingGetData = true;
                this.showData = false;
                this.showResult = false;
                this.tableDataRespuestas = [];
                this.tableDataRespuestasFil = [];
                this.tableDataRespuestasFilDos = [];
                this.dataGaleria=[];
                let r = await this.$peticionesApi('/api/Encuestas/EstadisticasEvaluacion',{data:filters,encuesta:enct.value});
                if (r != null){
                    //console.log(r);
                    this.loadingGetData = false;
                    this.showData = true;
                    this.showResult = true;
                    this.dataGaleria = r.DataImg;
                    this.totalImg = r.TotalFotos;
                    this.tableDataRespuestas = r.TablaRespuestas;
                    this.$toastMessage('success',r.Mensaje,9000,'center');
                    let a = 0;
                    for (let z = 0; z < this.dataGaleria.length;z++)
                    {
                        if(a<=20){
                            this.dataGaleria[z].ShowT = true;
                        }else{
                            this.dataGaleria[z].ShowT = false;
                        }
                        a++;
                    }
                    this.loadingGetData = false;
                }
            }
        },
        setFail(cve) {
            var idx = this.findIdx(cve);
            this.dataGaleria[idx].Mostrar = '1';
            this.dataGaleria[idx].Arch = '/theme/img/img.png';
            this.dataGaleria[idx].Disponible = '0';
        },
        onImgLoad(cve) {
            var idx = this.findIdx(cve);
            this.dataGaleria[idx].Mostrar = '1';
        },
        findIdx(cve){
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (this.dataGaleria[z].Id === cve){
                    a = z;
                }
            }
            return a;
        },
        loadData(cve){
            this.idxGlobalSelected = this.findIdx(cve);
        },
        mostrarveintemas(){ let $this = this;
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (a<=$this.imagesS){
                    $this.dataGaleria[z].ShowT = true;
                }
                a++;
            }
            this.imagesS = this.imagesS + 20;
        },
        async getReportDataRespuestas(){
            this.loadingGetData = true;
            await this.$peticionesApiFilesDownload('/api/Encuestas/exportLayoutRespuestasEvaluacion','ReporteEncuestasRespuestas.xlsx',this.tableDataRespuestas);
            this.loadingGetData=false;
        },
        ExportFile(){let $this = this;
            this.loadfileZip = true;
            this.$swal({
                title: "Espere",
                text: "Preparando zip " + this.tipoGaleria + " Poss, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/Zip',
                responseType: 'arraybuffer',
                data: {data:$this.dataGaleria,galeria:this.tipoGaleria}
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/zip' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadfileZip = false;
                    $this.$toastMessage('success','Zip descargado!',3000,'center');
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
        },
        ExportFilePptx(){let $this = this;
            this.loadFilePptx = true;
            this.$swal({
                title: "Espere",
                text: "Preparando presentación " + this.tipoGaleria + "Poss, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/Pptx',
                responseType: 'arraybuffer',
                data: {data:$this.dataGaleria,galeria:this.tipoGaleria}
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/pptx' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadFilePptx = false;
                    $this.$toastMessage('success','Presentación descargada!',3000,'center');
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
        },
    }
}
</script>

<style scoped>

</style>
