<template>
    <div>
        <v-select :multiple="multiples" :options="optionsCanales" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedCanales" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "canal",
    props:{
        placeholderm:String,
        multiples:Boolean
    },
    data(){
        return{
            selectedCanales:{label:"TODOS",value:0},
            optionsCanales:[]
        }
    },
    mounted() {
        this.getGestores();
    },
    methods:{
        getGestores(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/canalesSelect')
                .then(function (r){
                    $this.optionsCanales = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioCanal');
        }

    }
}
</script>

<style scoped>

</style>
