<template>
    <div class="border">
        <br><br>
        <div class="row">
            <div class="col-md-12 text-center">
                <h2><span class="text-primary">EXHIBICIONES</span></h2>
                <br>
                <b>Tipo Reporte:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                <p-radio class="p-default p-round" name="radio1" style="font-size: 16px" @click.native="abrir" checked color="success">Semanal</p-radio>
                <!-- or if it's only p-default and p-round you can leave out class attribute -->
                <p-radio name="radio1" style="font-size: 16px" @click.native="abrir" color="success">Mensual</p-radio>
            </div>
            <div class="col-md-12">
                <br>
                <div class="row">
                    <div class="col-md-2">
                        <label><b>Año:</b></label><br>
                        <select-anios ref="selectedAnio" :placeholderm="'Año'"></select-anios>
                    </div>
                    <div class="col-md-2">
                        <label class="typo__label"><b>Canales:</b></label>
                        <select-canalesN :placeholderm="'Canal'" ref="selectCanal" v-on:cambioCanal="changeCanal"></select-canalesN>
                    </div>
                    <div class="col-md-2">
                        <label class="typo__label">Cadenas:</label>
                        <select-cadenasN :placeholderm="'Cadena'" ref="selectCadena" v-on:cambioCadena="changeCadena"></select-cadenasN>
                    </div>
                    <div class="col-md-2">
                        <label><b>Categoria:</b></label><br>
                        <categorias-select :mltp="true" :placeholderm="'Categoria'" ref="Categoria"></categorias-select>
                    </div>
                    <div class="col-md-4 text-center"><br>
                        <button class="btn btn-primary dim text-center" type="button" @click="getReportDetalle">
                            <i class="fa fa-cogs"> Generar Reporte</i>
                        </button>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-12">
                <div v-if="showLoad" class="text-center">
                    <img :src="'/theme/img/loads/spiralload.gif'" alt="carga reporte" height="40">
                    <p>Consultando base de datos, por favor espere...</p>
                </div>
                <div v-else class="text-center">
                    <!--<highcharts :options="chartOptions" style="height: 400px; min-width: 100%"></highcharts>
                    <br>-->
                    <button class="btn btn-outline btn-success dim" type="button"
                            @click="showImpacto = false; showImpulso=false; showTotales=true">
                        <i class="fa fa-cogs"></i> TOTALES
                    </button>
                    <button class="btn btn-outline btn-info dim" type="button"
                            @click="showImpacto = true; showImpulso=false; showTotales=false">
                        <i class="fa fa-boxes"></i> ALTO IMPACTO
                    </button>
                    <button class="btn btn-outline btn-warning dim" type="button"
                            @click="showImpacto = false; showImpulso=true; showTotales=false">
                        <i class="fa fa-box"></i> IMPULSO
                    </button>
                    <br>
                    <highcharts :options="chartOptionsTotales" style="height: 400px; min-width: 100%" v-show="showTotales"></highcharts>
                    <highcharts :options="chartOptionsImpacto" style="height: 400px; min-width: 100%" v-show="showImpacto"></highcharts>
                    <highcharts :options="chartOptionsImpulso" style="height: 400px; min-width: 100%" v-show="showImpulso"></highcharts>
                </div>
            </div>
        </div>
        <div style="display:none" clas="row">
            <div class="col-md-12">
                <div class="text-center">
                    <div class="col-md-6">
                        <label class="typo__label">Productos:</label>
                        <multiselect v-model="filters.productos" :options="optionsProductosReal" :multiple="false" :close-on-select="true"
                                     :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectProducto">
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                    {{ selectTodosProductos ? 'Todos' : values.length > 1 ? values.length + ' productos' : values.length + ' producto' }}
                                </span>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <br><br>
                <highcharts :options="chartOptionsPto"></highcharts>
                <br><br>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
function createChartESNormal(vm){
    return{
        chart: {
            type: 'column',
        },

        title: {
            text: 'REPORTE EXHIBICIONES POR SEMANA'
        },
        xAxis: {
            title: {
                text: 'Semanas'
            },
            categories: [],
            min: 0,
            max:22
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        legend: {
            align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x} </b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',
                    dataLabels: {
                    enabled: true
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [
            {
                name: 'Impulso', color: '#e84611',
                data: []
            },
            {
                name: 'Alto Impacto', color: '#0072ff',
                data: []
            },
        ]
    }
}
function createChartESImpacto(vm){
    return{
        chart: {
            type: 'column',
            zoomType: 'x'
        },
        title: {
            text: 'REPORTE SEMANAL'
        },
        xAxis: {
            title: {
                text: 'Semanas'
            },
            categories: [],
            min: 0,
            max:22
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'dark'
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',

            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [
            {
                name: 'Alto Impacto', color: '#4c8bde',
                data: []
            },
            {
                type: 'spline',
                name: 'Alto Impacto ' + vm.pasado,
                data: [],
                marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[4],
                    fillColor: 'white'
                },
                dataLabels: {
                    enabled: true,

                    style: {
                        textOutline: 0,
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'dark'
                    }
                }
            },
        ]
    }
}
function createChartESImpulso(vm){
    return{
        chart: {
            type: 'column',
            zoomType: 'x'
        },
        title: {
            text: 'REPORTE SEMANAL'
        },
        xAxis: {
            title: {
                text: 'Semanas'
            },
            categories: [],
            min: 0,
            max:22
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'dark'
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',

            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [
            {
                name: 'Impulso', color: '#e84611',
                data: []
            },
            {
                type: 'spline',
                name: 'Impulso ' + vm.pasado,
                data: [],
                marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: 'white'
                },
                dataLabels: {
                    enabled: true,

                    style: {
                        textOutline: 0,
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'dark'
                    }
                }
            }
        ]
    }
}
function createChartESTotaltes(vm){
    return{
        chart: {
            type: 'column',
            zoomType: 'x'
        },
        title: {
            text: 'REPORTE SEMANAL'
        },
        xAxis: {
            title: {
                text: 'Semanas'
            },
            categories: [],
            min: 0,
            max:22
        },
        scrollbar: {
            enabled: true
        },
        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,

                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'dark'
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.x} </b><br/>',


        },
        plotOptions: {
            column: {
                stacking: 'normal',

            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [
            {
                name: 'Impulso', color: '#e84611',
                data: []
            },
            {
                name: 'Alto Impacto', color: '#4c8bde',
                data: []
            },
            {
                type: 'spline',
                name: 'Total ' + vm.pasado,
                data: [],
                marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[7],
                    fillColor: 'white'
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        textOutline: 0,
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'dark'
                    }
                }
            },
        ]
    }
}
function createChartPTO(vm){
    return{
        chart: {
            type: 'column',
        },
        scrollbar:{
            enabled:true
        },
        title: {
            text: 'REPORTE EXHIBICIONES POR SEMANA POR PRODUCTO'
        },
        xAxis: {
            title: {
                text: 'Semanas'
            },
            categories: [],
            min: 0,
            max:22
        },

        yAxis: {
            min: 0,
                title: {
                text: 'Total Exhibiciones'
            },
            stackLabels: {
                enabled: true,
                    style: {
                    fontWeight: 'bold',
                        color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        legend: {
            align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',

        },
        plotOptions: {
            column: {
                stacking: 'normal',
                    dataLabels: {
                    enabled: true
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [
            {
                name: 'IMPACTO', color: '#e84611',
                data: []
            },
            {
                name: 'IMPULSO', color: '#4c8bde',
                data: []
            },
            {
                type: 'spline',
                name: 'PRODUCTO 2020',
                data: [],
                marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: 'white'
                }
            }
        ]
    }
}
export default {
    name: "reporteExhibicionesSemana",
    data(){
        return{
            showLoad:true,
            value6: new Date(),
            selectTodosCanales:false,
            selectTodasCadenas:false,
            selectTodosProductos:false,
            showTotales:true,
            showImpacto:false,
            showImpulso:false,
            optionsCanalesReal:[],
            optionsProductosReal:[],
            optionsCadena:[],
            optionsCadenaReal:[],
            filters:{
                canales:null,
                canalesReal:null,
                cadenas:null,
                cadenasReal:null,
                productos:null,
                productosReal:null,
            },
            category:'',
            oculta:false,
            varsi:[],
            pasado: 0,
            chartOptionsTotales: createChartESTotaltes(this),
            chartOptionsImpulso: createChartESImpulso(this),
            chartOptionsImpacto: createChartESImpacto(this),
            chartOptions: createChartESNormal(this),
            chartOptionsPto: createChartPTO(this),
            typeReport:true,
        }
    },
    mounted() {
        //this.getProductos();
        this.getReportDetalle();
    },
    methods:{
        changeCanal(){
            var canal = this.$refs.selectCanal.selectedCanales;
            var selectTodas = false;
            if (canal.length>0){
                this.$refs.selectCadena.selectedCadenas = [];
                canal.forEach(function (value,key){
                    if (canal[key].value === 0){
                        selectTodas = true;
                    }
                });
                var cadena = this.$refs.selectCadena;
                if (selectTodas){
                    cadena.canal = this.$refs.selectCanal.optionsCanales;
                    this.filters.canalesReal = this.$refs.selectCanal.optionsCanales;
                    cadena.getCadenas();
                }
                else{
                    cadena.canal = canal;
                    this.filters.canalesReal = canal;
                    cadena.getCadenas();
                }
            }
            else{
                this.filters.canalesReal = null;
                this.filters.cadenasReal = null;
                this.$refs.selectCadena.selectedCadenas = [];
            }
        },
        changeCadena(){
            var cadenas = this.$refs.selectCadena.selectedCadenas;
            var selectTodas = false;
            if (cadenas.length>0){
                cadenas.forEach(function (value,key){
                    if (cadenas[key].value === 0){
                        selectTodas = true;
                    }
                });
                if (selectTodas){
                    this.filters.cadenasReal = this.$refs.selectCadena.optionsCadenas;
                }
                else{
                    this.filters.cadenasReal = cadenas;
                }
            }
            else{
                this.filters.cadenasReal = null;
            }
        },
        abrir(){
            this.typeReport =  !this.typeReport;
        },
        getProductos(){let $this = this;
            axios.post('/api/AdminCatalogos/productos')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsProductos = r.data.Data;$this.optionsProductosReal = r.data.Data;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectCanal(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.canales = [];
                    $this.filters.canales.push({
                        "name":'TODOS',
                        "value":0,
                    })
                }else{
                    $this.filters.canalesReal = $this.filters.canales;
                }
            });
            if (selectedOption.length === 0){ $this.filters.canalesReal = $this.filters.canales; $this.optionsCadenaReal = $this.optionsCadena; }
            else{$this.optionsCadenaReal = [];}
            $this.filters.canales.forEach(function (value,key){
                $this.optionsCadena.forEach(function (valueIn,keyIn){
                    if (value.value === 0){
                        $this.selectTodosCanales = true;
                        $this.filters.canalesReal = $this.optionsCanalesReal;
                        $this.optionsCadenaReal = $this.optionsCadena;
                        return;
                    }
                    if (parseInt(value.value) === parseInt(valueIn.canal)){
                        $this.selectTodosCanales = false;
                        $this.optionsCadenaReal.push({name: '' + valueIn.name+ '', value:valueIn.value, canal:''+valueIn.canal+''});
                    }
                });
            });
        },
        SelectCadena(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.cadenas = [];
                    $this.filters.cadenas.push({
                        "name":'TODAS',
                        "value":0,
                        "canal":0,
                    })
                }else{
                    $this.filters.cadenasReal = $this.filters.cadenas;
                }
            });
            if (selectedOption.length === 0){ $this.filters.cadenasReal = $this.filters.cadenas;}
            this.filters.cadenas.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodasCadenas = true;
                    $this.filters.cadenasReal = $this.optionsCadenaReal;
                }
            });
        },
        SelectProducto(selectedOption, id){let $this = this;
            console.log(selectedOption);
            this.chartOptionsPto.xAxis.categories = [];
            this.chartOptionsPto.series[0].data = [];
            this.chartOptionsPto.series[1].data = [];
            axios.post('/api/Dashboard/exhibicionesSemanaProducto',{anio: 2021,producto:this.filters.productos.value})
                .then(r => {
                        if (r.data.Success){
                            if (!$this.typeReport){
                                $this.chartOptions.xAxis.max = 11;
                                $this.chartOptions.xAxis.title.text = 'Mes';
                                $this.chartOptionsNuevo.xAxis.max = 11;
                                $this.chartOptionsNuevo.xAxis.title.text = 'Mes';
                                $this.chartOptionsPto.xAxis.max = 11;
                                $this.chartOptionsPto.xAxis.title.text = 'Mes';
                            }
                            else{
                                $this.chartOptions.xAxis.max = 22;
                                $this.chartOptions.xAxis.title.text = 'Semanas';
                                $this.chartOptionsNuevo.xAxis.max = 22;
                                $this.chartOptionsNuevo.xAxis.title.text = 'Semanas';
                                $this.chartOptionsPto.xAxis.max = 22;
                                $this.chartOptionsPto.xAxis.title.text = 'Semanas';
                            }
                            $this.showLoad = false;
                            $this.chartOptionsPto.xAxis.categories = r.data.Semanas;

                            r.data.ProductoImpacto.forEach(function (value,key){
                                $this.chartOptionsPto.series[0].data.push(parseInt(value));
                            });
                            r.data.ProductoImpulso.forEach(function (value,key){
                                $this.chartOptionsPto.series[1].data.push(parseInt(value));
                            });

                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        getReportDetalle(){ let $this = this;
            var anios = this.$refs.selectedAnio.selectedAnios;
            if (anios === null){
                this.$toastMessage('error','Seleccione el año, para generar el reporte!',4000,'center');
            }
            else{
                this.chartOptions.xAxis.categories = [];
                this.chartOptions.series[0].data = [];
                this.chartOptions.series[1].data = [];
                ////// para la grafica con el comparativo del año pasado
                this.chartOptionsTotales.xAxis.categories = [];
                this.chartOptionsTotales.series[0].data = [];
                this.chartOptionsTotales.series[1].data = [];
                this.chartOptionsTotales.series[2].data = [];
                //
                this.chartOptionsImpulso.xAxis.categories = [];
                this.chartOptionsImpulso.series[0].data = [];
                this.chartOptionsImpulso.series[1].data = [];
                //
                this.chartOptionsImpacto.xAxis.categories = [];
                this.chartOptionsImpacto.series[0].data = [];
                this.chartOptionsImpacto.series[1].data = [];
                this.showLoad = true;
                var cat = this.$refs.Categoria.selectedCategorias;
                axios.post('/api/Dashboard/exhibicionesSemana',{anio: anios.value, canal:this.filters.canalesReal,cadena:this.filters.cadenasReal,categoria:cat, type: this.typeReport})
                    .then(r => {
                            if (r.data.Success){
                                $this.showLoad = false;
                                $this.chartOptions.xAxis.categories = r.data.Semanas;
                                $this.chartOptionsTotales.xAxis.categories = r.data.Semanas;
                                $this.chartOptionsImpulso.xAxis.categories = r.data.Semanas;
                                $this.chartOptionsImpacto.xAxis.categories = r.data.Semanas;

                                $this.chartOptionsTotales.series[2].name = 'Total '+r.data.AnioP;
                                $this.chartOptionsImpulso.series[1].name = 'Impulso '+r.data.AnioP;
                                $this.chartOptionsImpacto.series[1].name = 'Impacto '+r.data.AnioP;


                                if (!$this.typeReport){
                                    $this.chartOptionsTotales.xAxis.max = 11;
                                    $this.chartOptionsTotales.title.text = 'REPORTE MENSUAL TOTALES';
                                    $this.chartOptionsTotales.xAxis.title.text = 'Mes';
                                    $this.chartOptionsImpulso.xAxis.max = 11;
                                    $this.chartOptionsImpulso.title.text = 'REPORTE MENSUAL IMPULSO';
                                    $this.chartOptionsImpulso.xAxis.title.text = 'Mes';
                                    $this.chartOptionsImpacto.xAxis.max = 11;
                                    $this.chartOptionsImpacto.title.text = 'REPORTE MENSUAL IMPACTO';
                                    $this.chartOptionsImpacto.xAxis.title.text = 'Mes';

                                }
                                else{
                                    $this.chartOptionsTotales.xAxis.max = 19;
                                    $this.chartOptionsTotales.xAxis.title.text = 'Semanas';
                                    $this.chartOptionsTotales.title.text = 'REPORTE SEMANAL TOTALES';
                                    $this.chartOptionsImpulso.xAxis.max = 19;
                                    $this.chartOptionsImpulso.xAxis.title.text = 'Semanas';
                                    $this.chartOptionsImpulso.title.text = 'REPORTE SEMANAL IMPULSO';
                                    $this.chartOptionsImpacto.xAxis.max = 19;
                                    $this.chartOptionsImpacto.xAxis.title.text = 'Semanas';
                                    $this.chartOptionsImpacto.title.text = 'REPORTE SEMANAL IMPACTO';

                                }

                                r.data.Impulso.forEach(function (value,key){
                                    $this.chartOptionsTotales.series[0].data.push(parseInt(value.Total));
                                });
                                r.data.AltoImpacto.forEach(function (value,key){
                                    $this.chartOptionsTotales.series[1].data.push(parseInt(value.Total));
                                });
                                r.data.Totales.forEach(function (value,key){
                                    $this.chartOptionsTotales.series[2].data.push(parseInt(value));
                                });

                                r.data.Impulso.forEach(function (value,key){
                                    $this.chartOptionsImpulso.series[0].data.push(parseInt(value.Total));
                                });
                                r.data.ImpulsoPasado.forEach(function (value,key){
                                    $this.chartOptionsImpulso.series[1].data.push(parseInt(value.Total));
                                });

                                r.data.AltoImpacto.forEach(function (value,key){
                                    $this.chartOptionsImpacto.series[0].data.push(parseInt(value.Total));
                                });

                                r.data.AltoImpactoPasado.forEach(function (value,key){
                                    $this.chartOptionsImpacto.series[1].data.push(parseInt(value.Total));
                                });
                            }
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                    );
            }

        }
    }
}
</script>

<style scoped>

</style>
