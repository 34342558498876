<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filters-general-mayoreo ref="filtersReport"></filters-general-mayoreo>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-center">
                        <button class="btn ripple btn-primary" @click="getReport">Generar Reporte </button>
                    </div>
                    <div class="col-md-4" v-if="showResult">
                        <div v-if="loadfile">
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando archivo, espere...</p>
                        </div>
                        <button v-if="!loadfile"  class="btn btn-success" type="button" @click="ExportFile"><i class="fas fa-file-excel"></i> <i class="fe fe-download-cloud"></i><b> Descargar Excel</b></button>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center">
                            <div v-if="loadingGetData">
                                <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                <p>Preparando información, espere...</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-show="showResult">
                        <v-client-table :data="tableDataPreciosMayoreo" :columns="columnsPreciosMayoreo" :options="optionsPreciosMayoreo" class="table-responsive" ref="tablePromociones">
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tablaPrecios",
    data(){
        return{
            loadfile:false,
            showResult:false,
            loadingGetData: false,
            showData: false,
            columnsPreciosMayoreo: ['Fecha','Semana','Area','Agencia','Rol','Usuario','Estado','Ciudad','Canal','Cadena','Formato','Tienda','Kcmid','Fabricante','Departamento','Categoria','Marca','Codigo','Producto','Prcun','Prcmy','Prccj','Comentario'],
            tableDataPreciosMayoreo: [],
            optionsPreciosMayoreo: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Usuario','Tienda','Area','Formato','Canal','Cadena','Codigo','Producto','Prcun','Prcmy','Prccj','Kcmid','Fabricante','Departamento','Categoria','Marca'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Fecha: 'Fecha Hora Registro',
                    Gestor: 'Usuario',
                    Prcun: 'Precio Unitario',
                    Prcmy: 'Precio Mayoreo',
                    Prccj: 'Precio Caja',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Prcun: 'text-center',
                    Prcmy: 'text-center',
                    Prccj: 'text-center',
                    Comentario: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    mounted() {
        this.$refs.filtersReport.shows.trades = false;
    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        getReport(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }
            else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{
                this.loadingGetData = true;
                this.showData = false;
                this.showResult = false;
                this.tableDataPreciosMayoreo = [];
                axios.post('/api/Precios/TablaMayoreo',{data:filters})
                    .then(r => {
                            $this.loadingGetData = false;
                            $this.showData = true;
                            $this.tableDataPreciosMayoreo = r.data.Data;
                            $this.showResult = true;
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        },
        ExportFile(){let $this = this;
            this.loadfile = true;
            axios({
                method: 'post',
                url: '/api/Precios/exportLayoutToExcelMayoreo',
                responseType: 'arraybuffer',
                data: {data: this.tableDataPreciosMayoreo}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'PreciosMayoreoPoss.xlsx'
                    link.click()
                    $this.loadfile=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadfile=false;
                }
            );
        },
    }
}
</script>

<style scoped>

</style>
