<template>
    <div class="row" style="margin-top: 20px">
        <div class="col-md-12">
            <div class="card custom-card">
                <div class="card-title">
                </div>
                <div class="card-body">
                    <div><i class="fas fa-tachometer-alt mr-1 dash-icon"></i></div>
                    <h1 class="text-center"> Bienvenido </h1>
                    <h3 class="text-center"><b style="color: darkorange">{{nombre}}</b></h3>
                    <div class="row row-sm">
                        <div class="col-md-2"></div>
                        <div class="col-md-8 text-center">
                            <vue-weather style="margin: auto;"
                                api-key="45aee2ef715cfa91ed7957e8cfd37a70"
                                units="uk" language="es"
                                latitude="19.3910038"
                                longitude="-99.133208"
                            >
                                <span slot="title">Clima CDMX</span>
                            </vue-weather>
                        </div>
                        <div class="col-md-2">

                        </div>
                    </div>

                    <div class="row row-sm">
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="card custom-card">
                                <div class="card-body dash1">
                                    <div class="d-flex">
                                        <p class="mb-1 tx-inverse">Exhibiciones Registradas</p>
                                        <div class="ms-auto">
                                            <i class="fe fe-bar-chart-2 fs-20 text-primary"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 class="dash-25">568</h3>
                                    </div>
                                    <div class="progress mb-1">
                                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="70"
                                             class="progress-bar progress-bar-xs wd-70p" role="progressbar"></div>
                                    </div>
                                    <div class="expansion-label d-flex">
                                        <span class="text-muted">Mes Anterior</span>
                                        <span class="ms-auto"><i class="fa fa-caret-up me-1 text-success"></i>1.7%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="card custom-card">
                                <div class="card-body dash1">
                                    <div class="d-flex">
                                        <p class="mb-1 tx-inverse">Ventas Registradas</p>
                                        <div class="ms-auto">
                                            <i class="fe fe-dollar-sign fs-20 text-secondary"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 class="dash-25">$12,897</h3>
                                    </div>
                                    <div class="progress mb-1">
                                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="70"
                                             class="progress-bar progress-bar-xs wd-60p bg-secondary" role="progressbar">
                                        </div>
                                    </div>
                                    <div class="expansion-label d-flex">
                                        <span class="text-muted">Mes Anterior</span>
                                        <span class="ms-auto"><i class="fa fa-caret-down me-1 text-danger"></i>0.43%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="card custom-card">
                                <div class="card-body dash1">
                                    <div class="d-flex">
                                        <p class="mb-1 tx-inverse">Faltantes Registrados</p>
                                        <div class="ms-auto">
                                            <i class="fe fe-box fs-20 text-success"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 class="dash-25">11,234</h3>
                                    </div>
                                    <div class="progress mb-1">
                                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="70"
                                             class="progress-bar progress-bar-xs wd-50p bg-success" role="progressbar"></div>
                                    </div>
                                    <div class="expansion-label d-flex text-muted">
                                        <span class="text-muted">Mes Pasado</span>
                                        <span class="ms-auto"><i class="fa fa-caret-down me-1 text-danger"></i>1.44%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="card custom-card">
                                <div class="card-body dash1">
                                    <div class="d-flex">
                                        <p class="mb-1 tx-inverse">Tiendas Visitadas</p>
                                        <div class="ms-auto">
                                            <i class="fa fa-store fs-20 text-info"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 class="dash-25">789</h3>
                                    </div>
                                    <div class="progress mb-1">
                                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="70"
                                             class="progress-bar progress-bar-xs wd-40p bg-info" role="progressbar"></div>
                                    </div>
                                    <div class="expansion-label d-flex text-muted">
                                        <span class="text-muted">Mes Pasado</span>
                                        <span class="ms-auto"><i class="fa fa-caret-up me-1 text-success"></i>10.9%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Row -->
                    <div class="row row-sm">
                        <div class="col-sm-6">
                            <highcharts :options="chartOptionsComparativoFaltantes"></highcharts>

                        </div>
                        <div class="col-sm-6">
                            <highcharts :options="chartOptionsComparativoVentas"></highcharts>
                        </div>
                    </div>
                    <!-- Row -->
                    <div class="row row-sm">
                        <div class="col-sm-12 col-lg-12  col-xl-4">
                            <div class="card custom-card">
                                <div class="card-body">
                                    <div>
                                        <h6 class="card-title mb-1">Prioridades</h6>
                                        <p class="text-muted mb-0 card-sub-title">Preguntas activas, que responde el personal en tienda.</p>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="activity-block">
                                        <ul class="task-list">
                                            <li>
                                                <i class="task-icon bg-success"></i>
                                                <h6>¿El producto está al frente del recibidior? <small class="float-end text-muted tx-11">29 Oct
                                                    2019 - 30 Dic 2019</small></h6>
                                                <span class="text-muted tx-12">Prioridad ID: #4567</span>
                                            </li>
                                            <li>
                                                <i class="task-icon bg-secondary"></i>
                                                <h6>¿La promoción de deportes, sigue activa? <small class="float-end text-muted tx-11">29 Oct
                                                    2019 - 30 Dic 2019</small></h6>
                                                <span class="text-muted tx-12">Prioridad: #8976</span>
                                            </li>
                                            <li>
                                                <i class="task-icon bg-primary"></i>
                                                <h6>Producto balón, ¿Tiene la nueva presentación de empaque? <small class="float-end text-muted tx-11">29 Oct
                                                    2019 - 30 Dic 2019</small></h6>
                                                <span class="text-muted tx-12">Prioridad: #6290</span>
                                            </li>
                                            <li>
                                                <i class="task-icon bg-info"></i>
                                                <h6>New Item Added <small class="float-end text-muted tx-11">29 Oct
                                                    2019 - 30 Dic 2019</small></h6>
                                                <span class="text-muted tx-12">Prioridad #0235</span>
                                            </li>
                                            <li>
                                                <i class="task-icon bg-danger"></i>
                                                <h6>Purchase Cancellation <small class="float-end text-muted tx-11">29 Oct
                                                    2019 - 30 Dic 2019</small></h6>
                                                <span class="text-muted tx-12">Prioridad: #1905</span>
                                            </li>
                                            <li class="mb-0">
                                                <i class="task-icon bg-warning"></i>
                                                <h6>Overdue Shipments <small class="float-end text-muted tx-11">29 Oct
                                                    2019 - 30 Dic 2019</small></h6>
                                                <span class="text-muted tx-12">Prioridad: #8902</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-xl-4">
                            <div class="card custom-card">
                                <div class="card-body">
                                    <div>
                                        <h6 class="card-title mb-1">Empleados mayor actividad</h6>
                                        <p class="text-muted mb-0 card-sub-title">Evaluación de desempeño de empleados.</p>
                                    </div>
                                </div>
                                <div class="user-manager scroll-widget border-top">
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <tbody>
                                            <tr>
                                                <td class="bd-t-0">
                                                    <div class="main-img-user"><img alt="avatar"
                                                                                    src="/theme/img/users/2.jpg"></div>
                                                </td>
                                                <td class="bd-t-0">
                                                    <h6 class="mg-b-0">Socrates Itumay</h6><small
                                                    class="tx-11 tx-gray-500">Acceso a Poss</small>
                                                </td>
                                                <td class="bd-t-0">
                                                    <h6 class="mg-b-0 font-weight-bold">34%</h6><small
                                                    class="tx-11 tx-gray-500">Acceso Semanal</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="main-img-user"><img alt="avatar"
                                                                                    src="/theme/img/users/3.jpg"></div>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0">Reynante Labares</h6><small
                                                    class="tx-11 tx-gray-500">Acceso a apk Poss</small>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0 font-weight-bold">22%</h6><small
                                                    class="tx-11 tx-gray-500">Acceso Semanal</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="main-img-user"><img alt="avatar"
                                                                                    src="/theme/img/users/4.jpg"></div>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0">Owen Bongcaras</h6><small
                                                    class="tx-11 tx-gray-500">Registro de exhibiciones</small>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0 font-weight-bold">18%</h6><small
                                                    class="tx-11 tx-gray-500">Acceso Semanal</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="main-img-user"><img alt="avatar"
                                                                                    src="/theme/img/users/5.jpg"></div>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0">Mariane Galeon</h6><small
                                                    class="tx-11 tx-gray-500">Registro de ventas</small>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0 font-weight-bold">15%</h6><small
                                                    class="tx-11 tx-gray-500">Acceso Semanal</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="main-img-user"><img alt="avatar"
                                                                                    src="/theme/img/users/6.jpg"></div>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0">Joyce Chua</h6><small
                                                    class="tx-11 tx-gray-500"><b>Aplicación de supervisión</b></small>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0 font-weight-bold">12%</h6><small
                                                    class="tx-11 tx-gray-500">Acceso Semanal</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="main-img-user"><img alt="avatar"
                                                                                    src="/theme/img/users/7.jpg"></div>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0">Sonia Fraser</h6><small
                                                    class="tx-11 tx-gray-500">Visitas a tiendas</small>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0 font-weight-bold">11%</h6><small
                                                    class="tx-11 tx-gray-500">Acceso Semanal</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="main-img-user"><img alt="avatar"
                                                                                    src="/theme/img/users/4.jpg"></div>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0">Owen Bongcaras</h6><small
                                                    class="tx-11 tx-gray-500">Registro fotográfico</small>
                                                </td>
                                                <td>
                                                    <h6 class="mg-b-0 font-weight-bold">18%</h6><small
                                                    class="tx-11 tx-gray-500">Acceso Semanal</small>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-xl-4 ">
                            <div class="card custom-card">
                                <div class="card-body">
                                    <div>
                                        <h6 class="card-title mb-1">Ventas en ciudades</h6>
                                        <p class="text-muted mb-0 card-sub-title">Registro de ventas por ciudad.</p>
                                    </div>
                                </div>
                                <div class="country-sales scroll-widget bd-t">
                                    <div class="list-group">
                                        <div class="list-group-item  d-flex border-right-0 border-left-0 border-top-0">
                                            <i class="flag-icon flag-us flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">TOLUCA</p>
                                            <span class="ms-auto font-weight-bold">$12,897</span>
                                        </div>
                                        <div class="list-group-item d-flex border-right-0 border-left-0">
                                            <i class="flag-icon flag-nl flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">CDMX</p>
                                            <span class="ms-auto font-weight-bold">$11,789</span>
                                        </div>
                                        <div class="list-group-item d-flex border-right-0 border-left-0">
                                            <i class="flag-icon flag-gb flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">MONTERREY</p>
                                            <span class="ms-auto font-weight-bold">$10,234</span>
                                        </div>
                                        <div class="list-group-item d-flex border-right-0 border-left-0">
                                            <i class="flag-icon flag-ca flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">VERACRUZ</p>
                                            <span class="ms-auto font-weight-bold">$9,104</span>
                                        </div>
                                        <div class="list-group-item d-flex border-right-0 border-left-0">
                                            <i class="flag-icon flag-au flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">NUEVO LEON</p>
                                            <span class="ms-auto font-weight-bold">$16,205</span>
                                        </div>
                                        <div class="list-group-item d-flex border-right-0 border-left-0">
                                            <i class="flag-icon flag-si flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">COLIMA</p>
                                            <span class="ms-auto font-weight-bold">$18,165</span>
                                        </div>
                                        <div class="list-group-item d-flex border-right-0 border-left-0 rounded-0">
                                            <i class="flag-icon flag-de flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">NUEVO LAREDO</p>
                                            <span class="ms-auto font-weight-bold">$19,765</span>
                                        </div>
                                        <div class="list-group-item d-flex border-right-0 border-left-0">
                                            <i class="flag-icon flag-si flag-icon-squared"></i>
                                            <p class="ms-3 mb-0">BAJA CALIFORNIA NORTE</p>
                                            <span class="ms-auto font-weight-bold">$18,165</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Row -->
                    <div class="pricing-tabs">
                        <div class=" text-center">
                            <div class="tab-content">
                                <div class="tab-pane  active show" id="year">
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-primary fs-20">FALTAS</div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">100</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>20
                                                            </strong> INCAPACIDAD</li>
                                                            <li><strong>20</strong>
                                                                FALLA EQUIPO CELULAR</li>
                                                            <li><strong>20</strong>
                                                                INCIDENTE EN TIENDA</li>
                                                            <li><strong>20</strong>
                                                                INCIDENTE TRANSPORTE</li>
                                                            <li><strong>20</strong>
                                                                OTROS</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-primary btn-block">Ver Reporte</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-secondary fs-20">PERSONAL
                                                    </div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">1,200</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>200
                                                            </strong> Centro Pais</li>
                                                            <li><strong>400</strong>
                                                                Sur Pais</li>
                                                            <li><strong>100</strong>
                                                                Norte Pais</li>
                                                            <li><strong>400</strong>
                                                                Occidente Pais</li>
                                                            <li><strong>100</strong>
                                                                Supervisores</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-secondary btn-block">Ver Reporte</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-info fs-20">TIENDAS POR CANAL</div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">4,200</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>1,200
                                                            </strong> Conveniencia</li>
                                                            <li><strong>2,350</strong>
                                                                Autoservicio</li>
                                                            <li><strong>520</strong>
                                                                Departamentales</li>
                                                            <li><strong>100</strong>
                                                                Mayoreo</li>
                                                            <li><strong>30</strong>
                                                                Otras</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-info btn-block">Ver Reporte</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-success fs-20">REGISTROS
                                                    </div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">5,000</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>10
                                                            </strong> Faltantes</li>
                                                            <li><strong>10</strong>
                                                                Exhibiciones</li>
                                                            <li><strong>8</strong>
                                                                Ventas</li>
                                                            <li><strong>3000</strong>
                                                                Inventarios</li>
                                                            <li><strong>247</strong>
                                                                Fotográfico</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-success btn-block">Ver Reporte</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="month">
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-primary fs-20">Free</div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">$0</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>2
                                                            </strong> FreeDomain Name</li>
                                                            <li><strong>0</strong>
                                                                One-Click Apps</li>
                                                            <li><strong>0</strong>
                                                                Databases</li>
                                                            <li><strong>Money</strong>
                                                                BackGuarntee</li>
                                                            <li><strong>24/7</strong>
                                                                Support</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-primary btn-block">Buy
                                                            Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-secondary fs-20">Personal
                                                    </div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">$15</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>2
                                                            </strong> FreeDomain Name</li>
                                                            <li><strong>2</strong>
                                                                One-Click Apps</li>
                                                            <li><strong>1</strong>
                                                                Databases</li>
                                                            <li><strong>Money</strong>
                                                                BackGuarntee</li>
                                                            <li><strong>24/7</strong>
                                                                Support</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-secondary btn-block">Buy
                                                            Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-info fs-20">Personal</div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">$25</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>3
                                                            </strong> FreeDomain Name</li>
                                                            <li><strong>5</strong>
                                                                One-Click Apps</li>
                                                            <li><strong>3</strong>
                                                                Databases</li>
                                                            <li><strong>Money</strong>
                                                                BackGuarntee</li>
                                                            <li><strong>24/7</strong>
                                                                Support</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-info btn-block">Buy
                                                            Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                                            <div class="card overflow-hidden">
                                                <div class="text-center card-pricing pricing1">
                                                    <div class="p-2 text-white bg-success fs-20">Enterprise
                                                    </div>
                                                    <div class="p-3 font-weight-normal mb-0"><span
                                                        class="price">$35</span></div>
                                                    <div class="card-body text-center pt-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li><strong>10
                                                            </strong> FreeDomain Name</li>
                                                            <li>><strong>10</strong>
                                                                One-Click Apps</li>
                                                            <li><strong>8</strong>
                                                                Databases</li>
                                                            <li><strong>Money</strong>
                                                                BackGuarntee</li>
                                                            <li><strong>24/7</strong>
                                                                Support</li>
                                                        </ul>
                                                    </div>
                                                    <div class="card-footer text-center">
                                                        <a href="#"
                                                           class="btn ripple btn-success btn-block">Buy
                                                            Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><br/><br/>
                    <div class="row row-sm">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <highcharts :options="chartOptionsTendencia"></highcharts>
                        </div>
                        <div class="col-md-2"></div>
                    </div><br><br><br>
                    <div class="row row-sm">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <div>
                                <v-select :multiple="false" :options="[{'label':'Todas','value':0},{'label':'AUTOSERVICIO','value':1},{'label':'FARMACIA','value':2},{'label':'GOBIERNO','value':3},{'label':'MAYORISTAS','value':4},
                                {'label':'MEMBRESIAS','value':5}]" :placeholder="'Selecciona Opción'"
                                          v-model="selectedTipo" @input="setCambio" ref="canalSelect"></v-select>
                            </div>
                        </div>
                        <div class="col-md-4"></div>
                    </div><br><br>
                    <div class="row row-sm">

                        <div class="col-md-1"></div>
                        <div class="col-md-5">
                            <highcharts :options="chartOptions"></highcharts>
                        </div>
                        <div class="col-md-5">
                            <highcharts :options="chartOptionsArea"></highcharts>
                        </div>
                        <div class="col-md-1"></div>
                    </div><br/><br/>
                    <div class="row row-sm">
                        <div class="col-md-4">
                            <div class="text-center">
                                <table class="table table-bordered table-striped" width="100%" role="grid" style="margin-left: 0px;">
                                    <thead>
                                    <th class="info text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 102.006px;" aria-label="Categoria: activate to sort column ascending">Categoria</th><th class="text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 45.0057px;" aria-label="Mes Actual: activate to sort column ascending">Mes Actual</th><th class="text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 47.0057px;" aria-label="Mes Anterior: activate to sort column ascending">Mes Anterior</th><th class="text-center sorting_desc" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 55px;" aria-sort="descending" aria-label="Año Actual: activate to sort column ascending">Año Actual</th>
                                    </thead>
                                    <tbody>
                                    <tr role="row" class="odd">
                                        <td class="text-left">PANTALLAS</td>
                                        <td class="text-center"><b>33,950.16</b></td><td><b>62,246.28</b></td>
                                        <td class="text-center sorting_1"><b>462,595.05</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">T0NNER</td>
                                        <td class="text-center"><b>21,415.42</b></td><td><b>39,121.31</b></td>
                                        <td class="text-center sorting_1"><b>285,714.95</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">COCINA</td>
                                        <td class="text-center"><b>7,518.86</b></td><td><b>13,650.46</b></td>
                                        <td class="text-center sorting_1"><b>96,519.70</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">SILLAS</td>
                                        <td class="text-center"><b>6,544.86</b></td><td><b>9,478.13</b></td>
                                        <td class="text-center sorting_1"><b>89,710.72</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">ROPA</td>
                                        <td class="text-center"><b>5,374.60</b></td><td><b>8,109.11</b></td>
                                        <td class="text-center sorting_1"><b>79,182.27</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">DEPORTE</td>
                                        <td class="text-center"><b>6,083.52</b></td><td><b>8,745.59</b></td>
                                        <td class="text-center sorting_1"><b>73,907.03</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">COCINA</td>
                                        <td class="text-center"><b>4,996.79</b></td><td><b>8,217.96</b></td>
                                        <td class="text-center sorting_1"><b>70,941.52</b></td>
                                    </tr></tbody>
                                </table>
                            </div>
                            <highcharts :options="chartOptionsComparativo"></highcharts>
                        </div>
                        <div class="col-md-4">
                            <div class="text-center">
                                <table class="table table-bordered table-striped" width="100%" role="grid" style="margin-left: 0px;">
                                    <thead>
                                    <th class="info text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 102.006px;" aria-label="Categoria: activate to sort column ascending">Canal</th><th class="text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 45.0057px;" aria-label="Mes Actual: activate to sort column ascending">Mes Actual</th><th class="text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 47.0057px;" aria-label="Mes Anterior: activate to sort column ascending">Mes Anterior</th><th class="text-center sorting_desc" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 55px;" aria-sort="descending" aria-label="Año Actual: activate to sort column ascending">Año Actual</th>
                                    </thead>
                                    <tbody>
                                    <tr role="row" class="odd">
                                        <td class="text-left">AUTOSERVICIO</td>
                                        <td class="text-center"><b>33,950.16</b></td><td><b>62,246.28</b></td>
                                        <td class="text-center sorting_1"><b>462,595.05</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">FARMACIA</td>
                                        <td class="text-center"><b>21,415.42</b></td><td><b>39,121.31</b></td>
                                        <td class="text-center sorting_1"><b>285,714.95</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">GOBIERNO</td>
                                        <td class="text-center"><b>7,518.86</b></td><td><b>13,650.46</b></td>
                                        <td class="text-center sorting_1"><b>96,519.70</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">MAYORISTAS</td>
                                        <td class="text-center"><b>6,544.86</b></td><td><b>9,478.13</b></td>
                                        <td class="text-center sorting_1"><b>89,710.72</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">MEMBRESIAS</td>
                                        <td class="text-center"><b>5,374.60</b></td><td><b>8,109.11</b></td>
                                        <td class="text-center sorting_1"><b>79,182.27</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">OTROS</td>
                                        <td class="text-center"><b>6,083.52</b></td><td><b>8,745.59</b></td>
                                        <td class="text-center sorting_1"><b>73,907.03</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">-</td>
                                        <td class="text-center"><b>6,083.52</b></td><td><b>8,745.59</b></td>
                                        <td class="text-center sorting_1"><b>73,907.03</b></td>
                                    </tr></tbody>
                                </table>
                            </div>
                            <highcharts :options="chartOptionsComparativoCanal"></highcharts>
                        </div>
                        <div class="col-md-4">
                            <div class="text-center">
                                <table class="table table-bordered table-striped" width="100%" role="grid" style="margin-left: 0px;">
                                    <thead>
                                    <th class="info text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 102.006px;" aria-label="Categoria: activate to sort column ascending">Área</th>
                                    <th class="text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 45.0057px;" aria-label="Mes Actual: activate to sort column ascending">Mes Actual</th>
                                    <th class="text-center sorting" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 47.0057px;" aria-label="Mes Anterior: activate to sort column ascending">Mes Anterior</th>
                                    <th class="text-center sorting_desc" tabindex="0" aria-controls="dataComparativoExhibiciones" rowspan="1" colspan="1" style="width: 55px;" aria-sort="descending" aria-label="Año Actual: activate to sort column ascending">Año Actual</th>
                                    </thead>
                                    <tbody>
                                    <tr role="row" class="odd">
                                        <td class="text-left">ÁREA 1</td>
                                        <td class="text-center"><b>33,950.16</b></td><td><b>62,246.28</b></td>
                                        <td class="text-center sorting_1"><b>462,595.05</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">ÁREA 2</td>
                                        <td class="text-center"><b>21,415.42</b></td><td><b>39,121.31</b></td>
                                        <td class="text-center sorting_1"><b>285,714.95</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">ÁREA 3</td>
                                        <td class="text-center"><b>7,518.86</b></td><td><b>13,650.46</b></td>
                                        <td class="text-center sorting_1"><b>96,519.70</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">ÁREA 4</td>
                                        <td class="text-center"><b>6,544.86</b></td><td><b>9,478.13</b></td>
                                        <td class="text-center sorting_1"><b>89,710.72</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">ÁREA 5</td>
                                        <td class="text-center"><b>5,374.60</b></td><td><b>8,109.11</b></td>
                                        <td class="text-center sorting_1"><b>79,182.27</b></td>
                                    </tr><tr role="row" class="even">
                                        <td class="text-left">ÁREA 6</td>
                                        <td class="text-center"><b>6,083.52</b></td><td><b>8,745.59</b></td>
                                        <td class="text-center sorting_1"><b>73,907.03</b></td>
                                    </tr><tr role="row" class="odd">
                                        <td class="text-left">-</td>
                                        <td class="text-center"><b>4,996.79</b></td><td><b>8,217.96</b></td>
                                        <td class="text-center sorting_1"><b>70,941.52</b></td>
                                    </tr></tbody>
                                </table>
                            </div>
                            <highcharts :options="chartOptionsComparativoArea"></highcharts>
                        </div>
                    </div><br/><br/>
                </div>
            </div>
        </div><br/><br/><br/>
    </div>
</template>

<script>

import VueWeather from "vue-weather-widget";
import Highcharts from "highcharts";
function createChartRespuestas(vm){
    return{
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Modulos de captura'
        },
        subtitle: {
            text: 'Captura realizada mensualmente'
        },
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45
            }
        },
        series: [{
            name: 'Medals',
            data: [
                ['Faltantes', 16],
                ['Exhibiciones', 12],
                ['Prioridades', 8],
                ['Fotográfico', 8],
                ['Promociones', 8],
                ['Comentarios', 6],
                ['Anaquel', 7],
                ['Precios', 4],
                ['Precios Mayoreo', 3]
            ]
        }]
    }
}
function createChartRespuestasArea(vm){
    return{
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        chart: {
            type: 'area'
        },
        title: {
            text: 'Promociones, registradas en Poss'
        },
        subtitle: {
            text: 'Source: <a ' +
                'href="https://www.ssb.no/energi-og-industri/energi/statistikk/elektrisitet/artikler/lavere-kraftproduksjon"' +
                ' target="_blank">SSB</a>'
        },
        xAxis: {
            categories: ['Q1 2019', 'Q2 2019', 'Q3 2019', 'Q4 2019', 'Q1 2020', 'Q2 2020',
                'Q3 2020', 'Q4 2020', 'Q1 2021', 'Q2 2021', 'Q3 2021']
        },
        yAxis: {
            title: {
                text: 'Catnidad'
            }
        },
        series: [{
            name: 'Total emplayes',
            data: [37.8, 29.3, 30.8, 36.8, 40.5, 35.3, 34.9, 43.6, 45.7, 35.9, 32.7]
        }, {
            name: 'Total cabeceras',
            data: [39.9, 29.9, 26.7, 38.1, 39.3, 30.2, 27.5, 36.7, 42.7, 31.4, 27.5]
        }, {
            name: 'Total precios',
            data: [2.2, 0.6, 4.1, 1.3, 1.2, 5.1, 7.4, 6.9, 2.9, 4.5, 5.2]
        }]
    }
}
function createChartRespuestasTendencia(vm){
    return{
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        title: {
            text: 'Venta de productos Agosto, CDMX',
            align: 'left'
        },
        xAxis: {
            categories: ['Pantallas', 'Deportes', 'Ropa', 'Electrónicos', 'Alimentos']
        },
        yAxis: {
            title: {text: 'Pesos'}
        },
        labels: {
            items: [{
                html: 'Total General',
                style: {
                    left: '50px',
                    top: '18px',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'black'
                }
            }]
        },
        series: [{
            type: 'column',
            name: '2020',
            data: [59, 83, 65, 228, 184]
        }, {
            type: 'column',
            name: '2021',
            data: [24, 79, 72, 240, 167]
        }, {
            type: 'column',
            name: '2022',
            data: [58, 88, 75, 250, 176]
        }, {
            type: 'spline',
            name: 'Average',
            data: [47, 83.33, 70.66, 239.33, 175.66],
            marker: {
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[3],
                fillColor: 'white'
            }
        }, {
            type: 'pie',
            name: 'Liter',
            data: [{
                name: '2020',
                y: 619,
                color: Highcharts.getOptions().colors[0] // 2020 color
            }, {
                name: '2021',
                y: 586,
                color: Highcharts.getOptions().colors[1] // 2021 color
            }, {
                name: '2022',
                y: 647,
                color: Highcharts.getOptions().colors[2] // 2022 color
            }],
            center: [80, 70],
            size: 100,
            showInLegend: false,
            dataLabels: {
                enabled: false
            }
        }]
    }
}
function createChartRespuestasComparativo(vm){
    return{
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        title: {
            text: 'Distribución Exhibiciones - Categoria'
        },
        series: [{
            type: "treemap",
            layoutAlgorithm: 'squarified',
            alternateStartingDirection: true,
            levels: [{
                level: 1,
                layoutAlgorithm: 'sliceAndDice',
                dataLabels: {
                    enabled: true,
                    align: 'left',
                    verticalAlign: 'top',
                    color: '#fff',
                    style: {
                        fontSize: '13px',
                        textShadow: false,
                        textOutline: "0px"
                    }
                },
            }, {
                level: 2,
                dataLabels: {
                    formatter: function () {
                        return Number(this.point.value).toLocaleString('en') + '%<br/>' +
                            this.point.name;
                    },
                    color: '#fff',
                    style: {
                        fontSize: '13px',
                        textShadow: false,
                        textOutline: "0px"
                    }
                }
            }],
            data: [
                {
                    id: 'Mas',
                    name: ' ',
                }, {
                    id: 'Bajo',
                    name: ' ',
                }, {
                    id: 'Medio',
                    name: ' ',
                }, {
                    id: 'Restante',
                    name: ' ',

                },
                {
                    name: 'PANTALLAS',
                    parent: 'Restante',
                    value: 32.4,
                    color: '#2c9f45'
                }, {
                    name: 'TONNER',
                    parent: 'Restante',
                    value: 20.0,
                    color: '#cf8d2e'
                }, {
                    name: 'COCINA',
                    parent: 'Restante',
                    value: 6.8,
                    color: '#be0027'
                }, {
                    name: 'SILLAS',
                    parent: 'Restante',
                    value: 6.3,
                    color: '#4298b5'
                }, {
                    name: 'ROPA',
                    parent: 'Restante',
                    value: 5.5,
                    color: '#00205b'
                }, {
                    name: 'DEPORTE',
                    parent: 'Restante',
                    value: 5.2,
                    color: '#efdf00'
                }, {
                    name: 'COMEDOR',
                    parent: 'Restante',
                    value: 5.0,
                    color: '#8996ee'
                }
            ]
        }]
    }
}
function createChartRespuestasComparativoCanal(vm){
    return{
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        title: {
            text: 'Distribución Exhibiciones - Canal'
        },
        series: [{
            type: "treemap",
            layoutAlgorithm: 'squarified',
            alternateStartingDirection: true,
            levels: [{
                level: 1,
                layoutAlgorithm: 'sliceAndDice',
                dataLabels: {
                    enabled: true,
                    align: 'left',
                    verticalAlign: 'top',
                    color: '#fff',
                    style: {
                        fontSize: '13px',
                        textShadow: false,
                        textOutline: "0px"
                    }
                },
            }, {
                level: 2,
                dataLabels: {
                    formatter: function () {
                        return Number(this.point.value).toLocaleString('en') + '%<br/>' +
                            this.point.name;
                    },
                    color: '#fff',
                    style: {
                        fontSize: '13px',
                        textShadow: false,
                        textOutline: "0px"
                    }
                }
            }],
            data: [
                {
                    id: 'Mas',
                    name: ' ',
                }, {
                    id: 'Bajo',
                    name: ' ',
                }, {
                    id: 'Medio',
                    name: ' ',
                }, {
                    id: 'Restante',
                    name: ' ',

                },
                {
                    name: 'AUTOSERVICIO',
                    parent: 'Restante',
                    value: 32.4,
                    color: '#2c9f45'
                }, {
                    name: 'FARMACIA',
                    parent: 'Restante',
                    value: 20.0,
                    color: '#cf8d2e'
                }, {
                    name: 'GOBIERNO',
                    parent: 'Restante',
                    value: 6.8,
                    color: '#be0027'
                }, {
                    name: 'MAYORISTAS',
                    parent: 'Restante',
                    value: 6.3,
                    color: '#4298b5'
                }, {
                    name: 'MEMBRESIAS',
                    parent: 'Restante',
                    value: 5.5,
                    color: '#00205b'
                },
            ]
        }]
    }
}
function createChartRespuestasComparativoArea(vm){
    return{
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        title: {
            text: 'Distribución Exhibiciones - Área'
        },
        series: [{
            type: "treemap",
            layoutAlgorithm: 'squarified',
            alternateStartingDirection: true,
            levels: [{
                level: 1,
                layoutAlgorithm: 'sliceAndDice',
                dataLabels: {
                    enabled: true,
                    align: 'left',
                    verticalAlign: 'top',
                    color: '#fff',
                    style: {
                        fontSize: '13px',
                        textShadow: false,
                        textOutline: "0px"
                    }
                },
            }, {
                level: 2,
                dataLabels: {
                    formatter: function () {
                        return Number(this.point.value).toLocaleString('en') + '%<br/>' +
                            this.point.name;
                    },
                    color: '#fff',
                    style: {
                        fontSize: '13px',
                        textShadow: false,
                        textOutline: "0px"
                    }
                }
            }],
            data: [
                {
                    id: 'Mas',
                    name: ' ',
                }, {
                    id: 'Bajo',
                    name: ' ',
                }, {
                    id: 'Medio',
                    name: ' ',
                }, {
                    id: 'Restante',
                    name: ' ',

                },
                {
                    name: 'ÁREA 2',
                    parent: 'Restante',
                    value: 32.4,
                    color: '#2c9f45'
                }, {
                    name: 'ÁREA 3',
                    parent: 'Restante',
                    value: 20.0,
                    color: '#cf8d2e'
                }, {
                    name: 'ÁREA 5',
                    parent: 'Restante',
                    value: 6.8,
                    color: '#be0027'
                }, {
                    name: 'ÁREA 1',
                    parent: 'Restante',
                    value: 6.3,
                    color: '#4298b5'
                }, {
                    name: 'ÁREA 4',
                    parent: 'Restante',
                    value: 5.5,
                    color: '#00205b'
                },{
                    name: 'ÁREA 6',
                    parent: 'Restante',
                    value: 45.5,
                    color: '#e84611'
                }
            ]
        }]
    }
}
function createChartRespuestasComparativoFaltantes(vm){
    return{
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'FALTANTES, AGOSTO 2022'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    connectorColor: 'silver'
                }
            }
        },
        series: [{
            name: 'Share',
            data: [
                { name: 'PANTALLAS', y: 73.24 },
                { name: 'ROPA', y: 12.93 },
                { name: 'DEPORTE', y: 4.73 },
                { name: 'SILLAS', y: 2.50 },
                { name: 'COCINA', y: 1.65 },
                { name: 'TONNER', y: 4.93 }
            ]
        }]
    }
}
function createChartRespuestasComparativovVentas(vm){
    return {
        credits: {
            enabled: true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        title: {
            text: 'VENTAS PRODUCTOS TOP 5'
        },

        subtitle: {
            text: ''
        },

        yAxis: {
            title: {
                text: 'Número de ventas'
            }
        },

        xAxis: {
            accessibility: {
                rangeDescription: 'Rango: 2012 - 2022'
            }
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2012
            }
        },

        series: [{
            name: 'PANTALLAS 40 PULGADAS',
            data: [73934, 48656, 65165, 81827, 112143, 142383,
                171533, 165174, 155157, 161454, 154610]
        }, {
            name: 'ROPA DEPORTIVA',
            data: [24916, 37941, 29742, 29851, 32490, 30282,
                38121, 36885, 33726, 34243, 31050]
        }, {
            name: 'MANCUERNAS',
            data: [11744, 30000, 16005, 19771, 20185, 24377,
                32147, 30912, 29243, 29213, 25663]
        }, {
            name: 'COMEDOR',
            data: [null, null, null, null, null, null, null,
                null, 11164, 11218, 10077]
        }, {
            name: 'PANTALLAS 60 PULGADAS',
            data: [21908, 5548, 8105, 11248, 8989, 11816, 18274,
                17300, 13053, 11906, 10073]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    }
}

export default {
    components:{
        VueWeather
    },
    props:{
        nombre:String
    },
    name: "welcome",
    data(){
        return{
            chartOptions: createChartRespuestas(this),
            chartOptionsArea: createChartRespuestasArea(this),
            chartOptionsTendencia: createChartRespuestasTendencia(this),
            chartOptionsComparativo: createChartRespuestasComparativo(this),
            chartOptionsComparativoCanal: createChartRespuestasComparativoCanal(this),
            chartOptionsComparativoArea: createChartRespuestasComparativoArea(this),
            chartOptionsComparativoFaltantes: createChartRespuestasComparativoFaltantes(this),
            chartOptionsComparativoVentas: createChartRespuestasComparativovVentas(this),
            selectedTipo:{'label':"Todas",'value':0}
        }
    },
    methods:{
        setCambio(){
            if (this.selectedTipo.value === 0)
            {
                this.chartOptions.series[0].data = [['Faltantes', 16], ['Exhibiciones', 12], ['Prioridades', 8], ['Fotográfico', 8], ['Promociones', 8], ['Comentarios', 6], ['Anaquel', 7], ['Precios', 4], ['Precios Mayoreo', 3]];
                this.chartOptionsArea.series[0].data = [37.8, 29.3, 30.8, 36.8, 40.5, 35.3, 34.9, 43.6, 45.7, 35.9, 32.7];
                this.chartOptionsArea.series[1].data = [39.9, 29.9, 26.7, 38.1, 39.3, 30.2, 27.5, 36.7, 42.7, 31.4, 27.5];
                this.chartOptionsArea.series[2].data = [2.2, 0.6, 4.1, 1.3, 1.2, 5.1, 7.4, 6.9, 2.9, 4.5, 5.2];
            }
            if (this.selectedTipo.value === 1)
            {
                this.chartOptions.series[0].data = [
                    ['Faltantes', 10],
                    ['Exhibiciones', 22],
                    ['Prioridades', 12],
                    ['Fotográfico', 22],
                    ['Promociones', 40],
                    ['Comentarios', 16],
                    ['Anaquel', 17],
                    ['Precios', 34],
                    ['Precios Mayoreo', 13]
                ];
                this.chartOptionsArea.series[0].data = [12.8, 10.3, 21.8, 32.8, 51.5, 23.3, 32.9, 12.6, 43.7, 12.9, 12.7];
                this.chartOptionsArea.series[1].data = [22.9, 30.9, 12.7, 43.1, 12.3, 22.2, 43.5, 32.7, 12.7, 33.4, 32.5];
                this.chartOptionsArea.series[2].data = [42.2, 20.6, 24.1, 31.3, 34.2, 15.1, 37.4, 16.9, 32.9, 44.5, 15.2];
            }
            if (this.selectedTipo.value === 2)
            {
                this.chartOptions.series[0].data = [
                    ['Faltantes', 5],
                    ['Exhibiciones', 12],
                    ['Prioridades', 30],
                    ['Fotográfico', 41],
                    ['Promociones', 12],
                    ['Comentarios', 36],
                    ['Anaquel', 22],
                    ['Precios', 10],
                    ['Precios Mayoreo', 33]
                ];
                this.chartOptionsArea.series[0].data = [34.8, 23.3, 12.8, 12.8, 10.5, 10.3, 13.9, 12.6, 12.7, 21.9, 16.7];
                this.chartOptionsArea.series[1].data = [12.9, 12.9, 33.7, 32.1, 32.3, 11.2, 15.5, 30.7, 32.7, 22.4, 17.5];
                this.chartOptionsArea.series[2].data = [10.2, 34.6, 55.1, 12.3, 12.2, 12.1, 51.4, 21.9, 12.9, 11.5, 25.2];
            }
            if (this.selectedTipo.value === 3)
            {
                this.chartOptions.series[0].data = [
                    ['Faltantes', 15],
                    ['Exhibiciones', 20],
                    ['Prioridades', 10],
                    ['Fotográfico', 44],
                    ['Promociones', 12],
                    ['Comentarios', 16],
                    ['Anaquel', 10],
                    ['Precios', 9],
                    ['Precios Mayoreo', 2]
                ];
                this.chartOptionsArea.series[0].data = [12.8, 12.3, 53.8, 12.8, 32.5, 12.3, 43.9, 43.6, 43.7, 12.9, 32.7];
                this.chartOptionsArea.series[1].data = [30.9, 33.9, 21.7, 32.1, 11.3, 43.2, 12.5, 21.7, 11.7, 43.4, 12.5];
                this.chartOptionsArea.series[2].data = [21.2, 12.6, 53.1, 10.3, 43.2, 12.1, 43.4, 32.9, 32.9, 12.5, 12.2];
            }
            if (this.selectedTipo.value === 4)
            {
                this.chartOptions.series[0].data = [
                    ['Faltantes', 1],
                    ['Exhibiciones', 40],
                    ['Prioridades', 10],
                    ['Fotográfico', 33],
                    ['Promociones', 61],
                    ['Comentarios', 12],
                    ['Anaquel', 21],
                    ['Precios', 19],
                    ['Precios Mayoreo', 40]
                ];
                this.chartOptionsArea.series[0].data = [34.8, 23.3, 12.8, 12.8, 10.5, 10.3, 13.9, 12.6, 12.7, 21.9, 16.7];
                this.chartOptionsArea.series[1].data = [12.9, 12.9, 33.7, 32.1, 32.3, 11.2, 15.5, 30.7, 32.7, 22.4, 17.5];
                this.chartOptionsArea.series[2].data = [10.2, 34.6, 55.1, 12.3, 12.2, 12.1, 51.4, 21.9, 12.9, 11.5, 25.2];
            }
            if (this.selectedTipo.value === 5)
            {
                this.chartOptions.series[0].data = [
                    ['Faltantes', 10],
                    ['Exhibiciones', 30],
                    ['Prioridades', 40],
                    ['Fotográfico', 3],
                    ['Promociones', 55],
                    ['Comentarios', 33],
                    ['Anaquel', 12],
                    ['Precios', 19],
                    ['Precios Mayoreo', 32]
                ];
                this.chartOptionsArea.series[0].data = [34.8, 23.3, 12.8, 12.8, 10.5, 10.3, 13.9, 12.6, 12.7, 21.9, 16.7];
                this.chartOptionsArea.series[1].data = [12.9, 12.9, 33.7, 32.1, 32.3, 11.2, 15.5, 30.7, 32.7, 22.4, 17.5];
                this.chartOptionsArea.series[2].data = [10.2, 34.6, 55.1, 12.3, 12.2, 12.1, 51.4, 21.9, 12.9, 11.5, 25.2];
            }

        }
    }
}
</script>

<style scoped>

</style>
