<template>
    <div>
        <v-select :multiple="multi" :options="optionsExhibiciones" :placeholder="'SELECCIONA EL TIPO DE '+placeholderm"
                  v-model="selectedExhibicioness" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "tipoExhibicion",
    props:{
        placeholderm: String,
        multi: Boolean,
    },
    data(){
        return{
            selectedExhibicioness:null,
            optionsExhibiciones:[],
            tipo:0,
        }
    },
    mounted() {
        this.getExhibiciones();
    },
    methods:{
        getExhibiciones(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/tipoExhibiciones',{tipo:this.tipo})
                .then(function (r){
                    $this.optionsExhibiciones = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioExhibiciones');
        }

    }
}
</script>

<style scoped>

</style>
