<template>
    <div>
        <v-select :multiple="false" :options="optionsClasificacion" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedClasificacion" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "clasificacion",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedClasificacion:[{label:"Todas",value:0}],
            optionsClasificacion:[{label:"Todas",value:0},{label:"Alto Impacto",value:1},{label:"Impulso",value:2}]
        }
    },
    mounted() {

    },
    methods:{
        setCambio(){
            this.$emit('cambioClasificacion');
        }

    }
}
</script>

<style scoped>

</style>
