<template>
    <div>
       <div class="row">
           <div class="col-md-3">
               <h5 class="text-info">Cargar archivo de tiendas Hermes</h5>
               <div class="form-group">
                   <label class="control-label">Archivo</label>
                   <div>
                       <input type="file"  id="archivo" name="archivo" class="form-control" required="" ref="fileRutas" v-on:change="handleFileUpload()"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                   </div>
               </div>
               <button v-if="!loadfile" class="btn btn-primary text-center" type="button" @click="submitFile()"><strong>Cargar archivo</strong></button><br><br>
               <button  class="btn btn-info text-center" type="button" @click="validarTiendas()"><strong>Validar Tiendas Hermes</strong></button>
               <div v-if="loadfile" class="text-center">
                   <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                   <p>Procesando archivo, espere...</p>
               </div>
           </div>
           <div class="col-md-9 text-center">
               <div class="row">
                   <div class="col-md-2">
                       <span class="text-center">Canales Alta: {{dataCanal.length}}</span>
                       <v-client-table :data="dataCanal" :columns="columnsCanales" :options="optionsCanales" class="table-responsive">
                       </v-client-table>
                   </div>
                   <div class="col-md-4">
                       <span class="text-center">Cadenas Alta: {{dataCadena.length}}</span>
                       <v-client-table :data="dataCadena" :columns="columnsCadena" :options="optionsCadena" class="table-responsive">
                       </v-client-table>
                   </div>
                   <div class="col-md-6">
                       <span class="text-center">Formatos Alta: {{dataFormato.length}}</span>
                       <v-client-table :data="dataFormato" :columns="columnsFormato" :options="optionsFormatos" class="table-responsive">
                       </v-client-table>
                   </div>
               </div>
           </div>
       </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-3">
                        <div class="text-center">
                            <b style="color: red">Estados Incorrectos</b>
                        </div>
                        <ul>
                            <li v-for="items in dataEstados"><span class="badge badge-success">{{items.Estado}}</span></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "importLayout",
    data(){
        return{
            loadfile:false,
            file: '',
            columnsCanales: ['Canal'],
            dataCanal:[],
            optionsCanales: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:4,
                perPageValues: [4,10,15,25,45,50],
                filterable: ['Canal'],
                columnsDropdown:false,
                headings: {
                },
                columnsClasses: {
                    Canal: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsCadena: ['Canal','Cadena'],
            dataCadena:[],
            optionsCadena: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:4,
                perPageValues: [4,10,15,25,45,50],
                filterable: ['Canal','Cadena'],
                columnsDropdown:false,
                headings: {
                },
                columnsClasses: {
                    Canal: 'text-justify',
                    Cadena: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsFormato: ['Canal','Cadena','Formato'],
            dataFormato:[],
            optionsFormatos: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:4,
                perPageValues: [4,10,15,25,45,50],
                filterable: ['Canal','Cadena','Formato'],
                listColumns: {
                    Estado:[]
                },
                columnsDropdown:false,
                columnsClasses: {
                    Canal: 'text-justify',
                    Cadena: 'text-justify',
                    Formato: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            dataEstados:[],
        }
    },
    mounted() {
    },
    methods:{
        validarTiendas(){let $this = this;
            this.loadfile=true;
            axios
                .post('/api/Encuestas/PeticionesNuevas',{tipo:6})
                .then(function (r){
                    $this.loadfile=false;
                    $this.$toastMessage('success',r.data.Mensaje,4000,'center');
                }).catch(function(){
                $this.$toastMessage('error','Error no definido, reportar a IT',4000,'center');
                $this.loadfile = false;
            });
        },
        handleFileUpload(){
            this.file = this.$refs.fileRutas.files[0];
        },
        submitFile(){ let $this = this;
            if (this.file === ''){
                $this.$toastMessage('error','Seleccione un archivo XLSX para procesar',4000,'center');
            }
            else{
                this.loadfile = true;
                let formData = new FormData();
                /*
                    Add the form data we need to submit
                */
                formData.append('file', this.file);
                /*
                  Make the request to the POST /single-file URL
                */
                axios.post( '/api/Tiendas/importHermes',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(r){
                    if (r.data.Success){
                        console.log(r.data.Data);
                        $this.$swal.fire({
                            icon: 'success',
                            title: 'ÉXITO',
                            text:  r.data.Mensaje,
                            footer: '<b>puede ver el resumen ahora!</b>'
                        });
                        $this.dataCanal = r.data.Data[0];
                        $this.dataCadena = r.data.Data[1];
                        $this.dataFormato = r.data.Data[2];
                        $this.dataEstados = r.data.Data[3];

                    }else{
                        $this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Archivo procesado con errores!',
                            footer: '<b>' + r.data.Mensaje + '</b>'
                        });
                    }
                    $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                })
                    .catch(function(){
                        console.log('FAILURE!!');
                        $this.$toastMessage('error','Error no definido, reportar a IT',4000,'center');
                        $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                    });
            }

        },
    }
}
</script>

<style scoped>

</style>
