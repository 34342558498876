<template>
    <div>
        <v-select :multiple="false" :options="optionsTrades" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedKpi" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "kpiSelect",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedKpi:[],
            optionsTrades:[]
        }
    },
    mounted() {
        this.getAreas();
    },
    methods:{
        getAreas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/getOptionsKpi')
                .then(function (r){
                    $this.optionsTrades = r.data.Data;
                });
        },
        setCambio(value){
            this.$emit('cambioKpi');
        }

    }
}
</script>

<style scoped>

</style>
