<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-content icons-box">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 b-r">
                                <h5 class="text-info">Crear ruta seleccionando las tiendas a visitar</h5>
                                    <div class="form-group" id="datForm">
                                        <label class="col-sm-2 control-label">Fecha</label>
                                        <div class="col-sm-10">
                                            <date-picker v-model="time1" valueType="YYYY-MM-DD"></date-picker>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-sm-10">
                                            <label><b>Supervisor</b></label>
                                            <gestores-component :placeholderm="'Supervisor'" v-on:cambioGestor="setGestor" ref="gestorSelect"></gestores-component>
                                        </div>
                                    </div>
                                <button class="btn btn-primary" type="button" @click="verRuta()"><strong>Ver ruta</strong></button>
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <h5 class="text-info">Cargar archivo de rutas</h5>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Archivo</label>
                                    <div class="col-sm-10">
                                        <input type="file"  id="archivo" name="archivo" class="form-control" required="" ref="fileRutas" v-on:change="handleFileUpload()">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <button v-if="!loadfile" class="btn btn-primary" type="button" @click="submitFile()"><strong>Cargar archivo</strong></button>
                                    </div>
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                        <div v-if="loadfile">
                                        <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                        <p>Preparando archivo, espere...</p>
                                        </div>
                                        <button v-if="!loadfile"  class="btn btn-primary" type="button" @click="ExportFile"><i class="fe fe-download-cloud"></i><b> Descargar Layout</b></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br><br>
        <transition name="fade">
            <div class="row" v-if="show">
                <div class="col-md-4">
                    <i class="typcn typcn-shopping-cart"></i> <b>Agregar tienda a ruta</b><br>
                    <div class="alert alert-outline-info" role="alert">
                        <small><i class="fe fe-alert-triangle"></i> Completa los datos correctamente, una vez registrado podras visualizarlo en la ruta seleccionada.
                            Los campos marcados con <b style="color: red">(*)</b> son obligatorios.</small>
                    </div>
                    <label><b>Cadena</b><b style="color: red">(*)</b></label>
                    <cadenas-component :placeholderm="'Cadena'" v-on:cambioCadena="getFormatos" ref="cadenaTienda"></cadenas-component>
                    <label><b>Formato</b><b style="color: red">(*)</b></label>
                    <formatos-component :placeholderm="'Formato'" v-on:cambioFormato="getTiendas" ref="formatoTienda"></formatos-component>
                    <label><b>Tienda</b><b style="color: red">(*)</b></label>
                    <tiendasmain-component :placeholderm="'Tienda'" ref="tiendaTienda"></tiendasmain-component><br>
                    <button class="btn btn-primary" type="button" @click="saveRuta()"><strong>Guardar</strong></button>
                </div>
                <div class="col-md-8">
                    <p>{{nombre}}: {{ ciudad }} - {{agencia}} <b>Fecha Ruta: {{time1}}</b></p>
                    <v-client-table :data="tableDataRuta" :columns="columnsRuta" :options="optionsRuta" class="table-responsive">
                        <div slot="Opciones" slot-scope="props">
                            <h5 v-if="validateFecha && props.row.Estatus === 'Pendiente'">
                                <i data-placement="top" data-toggle="tooltip-primary" title="Eliminar" class="fas fa-trash" style="color: red; cursor:pointer;" @click="EliminarRuta(props.row.Id)"></i>
                            </h5>
                        </div>
                    </v-client-table>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: "mainr",
    data(){
        return{
            file: '',
            time1:null,
            today:null,
            supervisor:null,
            show: false,
            columnsRuta: ['Id','KCMID','Cadena','Formato','Tienda','Entrada','Salida','Tiempo','Estatus','Motivo','Opciones'],
            tableDataRuta: [],
            optionsRuta: {
                footerHeadings:false,
                filterByColumn:false,
                alwaysShowPerPageSelect:false,
                filterable:false,
                pagination: { dropdown:false, show:false },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    KCMID: 'IDTIENDA',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Nombre: 'text-center',
                    Agencia: 'text-center',
                    Ciudad: 'text-center',
                    Opciones: 'text-center',
                    Area: 'text-center',
                    Tienda: 'text-justify',
                    KCMID: 'text-center font-bold',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            nombre:'',
            agencia:'',
            ciudad:'',
            loadfile:false,
            validateFecha:false,
        }
    },
    mounted() {
        this.time1 = moment().format('YYYY-MM-DD');
        this.today = moment().format('YYYY-MM-DD');
        //this.validateFecha =moment(this.time1).format('dd-mm-yyyy') >= moment(this.today).format('dd-mm-yyyy');

    },
    methods:{
        handleFileUpload(){
            this.file = this.$refs.fileRutas.files[0];
        },
        submitFile(){ let $this = this;
            if (this.file === ''){
                $this.$toastMessage('error','Seleccione un archivo CSV para procesar',4000,'center');
            }
            else{
                this.loadfile = true;
                let formData = new FormData();
                /*
                    Add the form data we need to submit
                */
                formData.append('file', this.file);
                /*
                  Make the request to the POST /single-file URL
                */
                axios.post( '/api/Main/importRutasLayout',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(r){
                    if (r.data.Success){
                        $this.$swal.fire({
                            icon: 'success',
                            title: 'ÉXITO',
                            text: 'archivo procesado correctamente!',
                            footer: '<b>' + r.data.Mensaje + '</b>'
                        });
                    }else{
                        $this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Archivo procesado con errores!',
                            footer: '<b>' + r.data.Mensaje + '</b>'
                        });
                    }
                    $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                })
                .catch(function(){
                    console.log('FAILURE!!');
                    $this.$toastMessage('error','Error no definido, reportar a IT',4000,'center');
                    $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                });
            }

        },
        EliminarRuta(cve){ let $this = this;
            this.$swal.fire({
                title: '¿Eliminar Ruta?',
                text: 'Esto eliminara la visita a la tienda',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Main/deleRuta',{delete:cve})
                    .then(function (r){
                        if (r.data.Success){
                            $this.verRuta();
                            $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                        }
                        else{
                            $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                        }
                    });
                }
            });
        },
        saveRuta(){ let $this = this;
            var a = this.$refs.tiendaTienda.selectedTiendas;
            if (a !== null){
                axios
                    .post('/api/Main/saveRuta',{fecha:this.time1,usuario:this.supervisor,tienda:a.value})
                    .then(function (r){
                        if (r.data.Success){
                            $this.$refs.tiendaTienda.optionsTiendas = [];
                            $this.$refs.tiendaTienda.selectedTiendas = null;
                            $this.$refs.tiendaTienda.optionsCadenas = [];
                            $this.$refs.cadenaTienda.selectedCadenas = null;
                            $this.$refs.formatoTienda.optionsFormatos = [];
                            $this.$refs.formatoTienda.selectedFormatos = null;
                            $this.verRuta();
                            $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                        }
                        else{
                            $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                        }

                    });
            }else{
                this.$toastMessage('error',"Seleccione la tienda para guardar la ruta!",3000,'top-right');
            }
        },
        setGestor(){
            if (this.$refs.gestorSelect.selectedGestores !== null){
                this.supervisor = this.$refs.gestorSelect.selectedGestores.value;
            }
            else{
                this.supervisor = null;
                this.show=false;
            }

        },
        verRuta(){ let $this = this;
            if (this.time1 === null){
                this.$toastMessage('error',"Seleccione la fecha de la ruta!",3000,'top-right');
            }
            else if (this.supervisor === null){
                this.$toastMessage('error',"Seleccione el supervisor, para ver su ruta",3000,'top-right')
            }
            else{
                axios
                    .post('/api/Main/getRutas',{fecha:this.time1,usuario:this.supervisor})
                    .then(function (r){
                        $this.tableDataRuta = r.data.Data;
                        $this.nombre = r.data.Nombre;
                        $this.agencia = r.data.Agencia;
                        $this.ciudad = r.data.Ciudad;
                        if (!$this.show){$this.show=true}

                        var dateA = moment($this.time1); // replace format by your one
                        var dateB = moment($this.today);
                        $this.validateFecha = (dateA.diff(dateB) >= 0);
                        //console.log(dateA.diff(dateB))
                    });

            }

        },
        getFormatos(){
            var a = this.$refs.cadenaTienda.selectedCadenas;
            if (a !== null){
                this.$refs.formatoTienda.cvecadena = a.value;
                this.$refs.formatoTienda.optionsFormatos = [];
                this.$refs.formatoTienda.getDataFormatos();
            }else{
                this.$refs.formatoTienda.optionsFormatos = [];
                this.$refs.formatoTienda.selectedFormatos = null;
                this.$refs.tiendaTienda.selectedTiendas = null;
                this.$refs.tiendaTienda.optionsTiendas = [];
            }
        },
        getTiendas(){
            var a = this.$refs.formatoTienda.selectedFormatos;
            if (a !== null){
                this.$refs.tiendaTienda.cveentfmt = a.value;
                this.$refs.tiendaTienda.optionsTiendas = [];
                this.$refs.tiendaTienda.getTiendas();
            }else{
                this.$refs.tiendaTienda.selectedTiendas = null;
                this.$refs.tiendaTienda.optionsTiendas = [];
            }
        },
        ExportFile(){let $this = this;
            this.loadfile = true;
            axios({
                method: 'post',
                url: '/api/Main/exportLayoutToExcel',
                responseType: 'arraybuffer',
                data: {typeUsers: this.typeUsers}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LayoutTrabajo.xlsx'
                    link.click()
                    $this.loadfile=false;
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
        },
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
}
</style>
