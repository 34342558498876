import { render, staticRenderFns } from "./importLayout.vue?vue&type=template&id=b8f066e4&scoped=true&"
import script from "./importLayout.vue?vue&type=script&lang=js&"
export * from "./importLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f066e4",
  null
  
)

export default component.exports