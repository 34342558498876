<template>
    <div>
        <!-- Modal effects -->
        <div class="modal" id="modalAddPermisos" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Asignar Permisos <i class="fas fa-mobile-alt"></i></h6>
                        <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-8">
                                        <select class="form-control" v-model="permiso">
                                            <option :value="0" selected>SELECCIONE OPCIÓN</option>
                                            <option :value="1">PRIORIDADES</option>
                                            <option :value="2">FALTANTES</option>
                                            <option :value="3">PROMOCIONES</option>
                                            <option :value="4">ANAQUEL</option>
                                            <option :value="5">EXHIBICIONES</option>
                                            <option :value="6">PRECIOS</option>
                                            <option :value="7">GRÁFICO</option>
                                            <option :value="8">COMENTARIOS</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4" v-if="permiso!==0">
                                        <i class="fa fa-save text-center" style="color: darkgreen;cursor: pointer" @click="savePermiso(1)"></i> ASIGNAR PERMISO
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-center">
                                    <p>PERMISOS ASIGNADOS</p>
                                    <br>
                                    <p v-for="items in permisos">{{items.Perm}} <i class="fa fa-trash" style="color: red;cursor: pointer" @click="permiso=items.Id;savePermiso(0)"></i></p>
                                </div>
                            </div>
                        </div>
                        <br><br><br><br>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal effects-->
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
    name: "editPermisos",
    data(){
        return{
            idEmpleado:0,
            permiso:0,
            permisos:[]
        }
    },

    methods: {
        async getPermisos(){
            var response = await this.$peticionesApi('/api/Users/getUsuariosPermisos',{cveusr:this.idEmpleado});
            if (response!=null){
                this.permisos = response.Permisos;
            }
        },
        async savePermiso(type){
            var response = await this.$peticionesApi('/api/Users/saveUsuariosPermisos',{cveusr:this.idEmpleado,permiso:this.permiso,type:type});
            if (response!=null){
                this.permisos = response.Permisos;
                await this.getPermisos();
                this.$toastMessage(response.Success, response.Mensaje, 4000, 'center');
            }
        }
    }
}
</script>

<style>
.vue-form-wizard .wizard-nav-pills > li > a {
    color: #d0d63a;
}
.multiselect__option--highlight{
    background: #d0d63a !important;
}

.is-danger .multiselect__tags {
    border-color: red;
}
</style>
