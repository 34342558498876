import { render, staticRenderFns } from "./tablaResumen.vue?vue&type=template&id=5620f604&scoped=true&"
import script from "./tablaResumen.vue?vue&type=script&lang=js&"
export * from "./tablaResumen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5620f604",
  null
  
)

export default component.exports