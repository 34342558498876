<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <label>Archivo para actualizar rutas
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" accept=".txt"/>
                </label>
                <br><br><br>
                <button v-if="!showLoad" @click="submitFile()" class="btn ripple btn-with-icon btn-primary">Procesar archivo <i class="fas fa-cogs"></i></button><br>
            </div>
            <div class="col-md-8">
                <div>
                    <div v-if="showLoad" class="text-center">
                        <img :src="'/theme/img/loads/spiralload.gif'" alt="carga reporte" height="40">
                        <p>Validando archivo y contenido, por favor espere...</p>
                    </div>
                </div>
                <div>
                    <div v-if="!showLoad" class="text-center">
                        <h1>RUTAS ACTUALIZADAS: {{aceptadas}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <i class="text-center far fa-eye" style="color: blue;cursor:pointer;" @click="showErroresLineas = !showErroresLineas"></i>
                        <label>Errores en lineas del archivo:</label><br>
                        <ol v-if="showErroresLineas">
                            <li v-for="items in ErroresLineas" v-html="items"></li>
                        </ol>
                    </div>
                    <div class="col-md-4">
                        <i class="text-center far fa-eye" style="color: blue;cursor:pointer;" @click="showErroresTiendas = !showErroresTiendas"></i>
                        <label>Errores de tiendas:</label><br>
                        <ol v-if="showErroresTiendas">
                            <li v-for="items in ErroresTiendas" v-html="items"></li>
                        </ol>
                    </div>
                    <div class="col-md-4">
                        <i class="text-center far fa-eye" style="color: blue;cursor:pointer;" @click="showErroresFechas = !showErroresFechas"></i>
                        <label>Errores en fechas:</label><br>
                        <ol v-if="showErroresFechas">
                            <li v-for="items in ErroresFechas" v-html="items"></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "actualizar",
    data(){
        return{
            showLoad:false,
            ErroresLineas:[],
            ErroresTiendas:[],
            ErroresFechas:[],
            showErroresLineas:false,
            showErroresTiendas:false,
            showErroresFechas:false,
            aceptadas:0
        }
    },
    methods:{
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
        async submitFile(){
            if (this.file === ''){
                this.$toastMessage('error','Seleccione un archivo txt para procesar',4000,'center');
            }
            else{
                this.showLoad = true;
                let formData = new FormData();
                /*
                    Add the form data we need to submit
                */
                formData.append('file', this.file);
                /*
                  Make the request to the POST /single-file URL
                */
                var response = await this.$peticionesApiFiles('/api/Rutero/actualizarRutero',formData);

                if (response.Success){
                    console.log(response.ErroresLineas);
                    this.ErroresLineas = response.ErroresLineas;
                    this.ErroresTiendas = response.ErrorTiendas;
                    this.ErroresFechas = response.ErrorFechas;
                    this.aceptadas = response.Aceptadas;
                    this.$swal.fire({
                        icon: 'success',
                        title: 'ÉXITO',
                        text: 'archivo procesado correctamente!',
                        footer: '<b>' + response.Mensaje + '</b>'
                    });
                }else{
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Archivo procesado con errores!',
                        footer: '<b>' + response.Mensaje + '</b>'
                    });
                    this.file = '' ;this.showLoad = false; this.$refs.file.value = null;
                }
                this.file = '';this.showLoad = false; this.$refs.file.value = null;
            }

        },
    }
}
</script>

<style scoped>

</style>
