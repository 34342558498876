<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <button class="btn btn-outline btn-warning dim" type="button" @click="GetData(1)">Pendientes</button>
                <button class="btn btn-outline btn-danger dim" type="button" @click="GetData(3)">Canceladas</button>
                <button class="btn btn-outline btn-success dim" type="button" @click="GetData(2)">Aprobadas</button>
                <button class="btn btn-outline btn-info dim" type="button" @click="GetData(4)">Actualizadas</button>
            </div>
            <div class="col-md-4 text-center">
                <button class="btn btn-outline btn-primary dim" type="button" @click="GetData(0)"><i class="fa fa-refresh"> Ver Todas Las Prioridades</i></button>
            </div>
            <div class="col-md-4"></div>
        </div><br>
        <div class="row">
            <div class="col-md-12">
                <v-client-table :data="tableData" :columns="columns" :options="options" class="table-responsive">
                    <div slot="Type" slot-scope="props" >
                        <span v-if="props.row.Type===0" class="badge badge-success">CLIENTE 1</span>
                        <span v-else class="badge badge-primary">Competencia</span>
                    </div>
                    <div slot="Estatus" slot-scope="props" >
                        <span v-if="props.row.Estatus === 1" class="badge badge-warning">Revisión</span>
                        <span v-if="props.row.Estatus === 2" class="badge badge-success">Aprobada</span>
                        <span v-if="props.row.Estatus === 3" class="badge badge-danger">Rechazada</span>
                        <span v-if="props.row.Estatus === 4" class="badge badge-info">Actualizada</span>
                    </div>
                    <div slot="Imagen" slot-scope="props">
                        <i class="fa fa-file-image-o" style="color: #0a6aa1;cursor:pointer" v-if="props.row.Imagen!==''" @click="showModalImg(props.row.Imagen)"> Ver</i>
                        <i class="fa fa-times" style="color: darkred" v-else></i>
                    </div>
                    <div slot="Autorizar" slot-scope="props">
                        <div v-if="props.row.Estatus===1">
                            <button class="btn btn-outline btn-primary dim" type="button" @click="Aprobar(props.row.Id)"><i class="fa fa-check"> Aprobar</i></button><br>
                            <button class="btn btn-outline btn-danger dim" type="button" @click="Rechazar(props.row.Id)"><i class="fa fa-times"> Rechazar</i></button>
                        </div>
                        <div v-if="props.row.Estatus===4">
                            <button class="btn btn-outline btn-primary dim" type="button" @click="AprobarUpdate(props.row.Id)"><i class="fa fa-check"> Aprobar</i></button><br>
                        </div>
                    </div>
                    <div slot="Areas" slot-scope="props">
                        <span v-html="props.row.Areas"></span>
                    </div>
                    <div slot="Agencias" slot-scope="props">
                        <span v-html="props.row.Agencias"></span>
                    </div>
                    <div slot="Estados" slot-scope="props">
                        <span v-html="props.row.Estados"></span>
                    </div>
                    <div slot="Canales" slot-scope="props">
                        <span v-html="props.row.Canales"></span>
                    </div>
                    <div slot="Cadenas" slot-scope="props">
                        <span v-html="props.row.Cadenas"></span>
                    </div>
                    <div slot="Formatos" slot-scope="props">
                        <span v-html="props.row.Formatos"></span>
                    </div>
                    <div slot="Marcas" slot-scope="props">
                        <span v-html="props.row.Marcas"></span>
                    </div>
                    <div slot="Departamento" slot-scope="props">
                        <span v-html="props.row.Departamento"></span>
                    </div>
                    <div slot="Categoria" slot-scope="props">
                        <span v-html="props.row.Categoria"></span>
                    </div>

                </v-client-table>
            </div>
        </div>
        <div class="modal inmodal" :class="{'show':showModal}" id="myModal4" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated fadeIn">
                    <div class="modal-header">
                        <i class="fa modal-icon" :class="iconModal" style="color: darkred"></i>
                        <h4 class="modal-title"><span>{{titleModal}}</span></h4>
                    </div>
                    <div class="modal-body">
                        <div v-show="rechazar">
                            <label><b style="color: darkred">Ingrese motivo de rechazo:</b></label>
                            <textarea rows="4" maxlength="200" class="form-control" v-model="motivoRechazo" placeholder="Motivo de rechazo"></textarea>
                        </div>
                        <div v-show="!rechazar">
                            <img :src="imgSrc" alt="Image" class="img img-responsive">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-show="rechazar" type="button" class="btn btn-success" data-dismiss="modal" @click="closeModal">Guardar Cambios</button>
                        <button v-show="rechazar" type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalF">Cancelar</button>
                        <button v-show="!rechazar" type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalImg">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "verEncuesstas",
    data() {
        return{
            Id:0,
            imgSrc:'',
            titleModal:'',
            iconModal:'',
            motivoRechazo:'',
            rechazar:true,
            showModal:false,
            columns: ['Id','Responsable','Type' ,'Fchini','Fchter','Nomentprd','Promocion','Tpo','Imagen','Comentario','Respuesta','Estatus','CreatedAt','Areas','Agencias','Estados','Canales','Cadenas','Formatos','Marcas','Departamento','Categoria'],
            tableData: [],
            options: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:true },
                perPage:15,
                perPageValues: [15,25,45,50],
                filterable: ['Id','Responsable','Nomentprd','Type','Fchini','Fchter','Promocion','Tpo','Comentario','Estatus','Productos'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                sortable: ['Id','Responsable','Type'],
                listColumns: {
                    Estatus: [{
                        id: 1,
                        text: 'Revisión'
                    }, {
                        id: 2,
                        text: 'Aprobada'
                    },{
                        id: 3,
                        text: 'Rechazada'
                    },{
                        id: 4,
                        text: 'Actualizadas'
                    }
                    ],
                    Type: [{
                        id: 0,
                        text: 'CLIENTE 1'
                    }, {
                        id: 1,
                        text: 'COMPETENCIA'
                    }]
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Nomentprd: 'Encuesta',
                    Fchini: 'Fecha Inicio',
                    Fchter: 'Fecha Termino',
                    Tpo: 'Tipo',
                    Type: 'Tipo',
                    CreatedAt: 'Creada el',
                },
                columnsClasses: {
                    Id: 'text-center font-weight-bold',
                    Nomentprd: 'text-justify',
                    Comentario: 'text-justify',
                    Respuesta: 'text-justify',
                    Fchini: 'text-center',
                    Fchter: 'text-center',
                    Promocion: 'text-center',
                    Tpo: 'text-center',
                    Type: 'text-center',
                    Imagen: 'text-center',
                    Estatus: 'text-center',
                    CreatedAt: 'text-center',
                    Areas: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    mounted () {
        this.GetData(1);
    },
    methods:{
        GetData(estatus){let $this = this;
            this.tableData=[];
            axios
                .post('/api/Encuestas/Peticiones',{combo:16,estatus:estatus})
                .then(function (r){
                    $this.tableData= Array.from(r.data.Data);
                });
        },
        showModalImg(src){
            this.titleModal='Imagen Prioridad';
            this.iconModal='fa-file-image-o';
            this.rechazar=false;
            this.imgSrc = src;
            this.showModal = true;
        },
        closeModal(){
            if (this.motivoRechazo===''){
                $this.$toastMessage("error",'Ingrese un motivo de rechazo por favor!',3000,'center');
            }else{let $this = this;
                axios
                    .post('/api/Encuestas/Peticiones',{combo:17,estatus:3,motivo:this.motivoRechazo,Id:this.Id})
                    .then(function (r){
                        $this.$toastMessage("success",r.data.Mensaje,3000,'center');
                        var idx = $this.getId(1);
                        $this.tableData[idx].Estatus = 3;
                        axios
                            .post('/api/Encuestas/Peticiones',{combo:22,Id:$this.Id})/// envio de email a los usuarios!
                            .then(function (r){
                                $this.$swal.fire(
                                    'Exito!',
                                    'Correo enviado, espera la notificación de modificación.',
                                    'success'
                                );
                            });
                    });
                this.showModal = false;
                this.motivoRechazo = '';

            }
        },
        closeModalImg(){
            this.showModal = false;
        },
        Aprobar(clave){let $this = this;
            this.Id = clave;
            axios
                .post('/api/Encuestas/Peticiones',{combo:20,estatus:2,motivo:this.motivoRechazo,Id:this.Id,update:0})
                .then(function (r){
                    $this.$toastMessage("success",r.data.Mensaje,3000,'center');
                    var idx = $this.getId(this.Id);
                    $this.tableData[idx].Estatus = 2;

                    axios.post('/api/Encuestas/Peticiones',{combo:23,Id:$this.Id})/// envio de email a los usuarios!
                        .then(function (r){
                            $this.$swal.fire(
                                'Exito!',
                                'Correo enviado para notificar la aprobación.',
                                'success'
                            );
                        });
                });

        },
        AprobarUpdate(clave){let $this = this;
            this.Id = clave;
            axios
                .post('/api/Encuestas/Peticiones',{combo:20,estatus:2,motivo:this.motivoRechazo,Id:this.Id,update:1})
                .then(function (r){
                    $this.$toastMessage("success",r.data.Mensaje,3000,'center');
                    var idx = $this.getId(this.Id);
                    $this.tableData[idx].Estatus = 2;

                    axios.post('/api/Encuestas/Peticiones',{combo:23,Id:$this.Id})/// envio de email a los usuarios!
                        .then(function (r){
                            $this.$swal.fire(
                                'Exito!',
                                'Correo enviado para notificar la aprobación.',
                                'success'
                            );
                        });
                });

        },
        Rechazar(clave){
            this.rechazar=true;
            this.Id = clave;
            this.titleModal='Rechazar Encuesta';
            this.iconModal='fa-times';
            this.showModal=true;
        },
        getId(clave){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableData.length;a++){
                if ($this.tableData[a].Id === clave){
                    idn = a;
                }
            }
            return idn;
        },
        closeModalF(){
            this.showModal=false;
        }

    }
}
</script>

<style scoped>

</style>
