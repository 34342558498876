<template>
    <div>
        <v-select :multiple="mltp" :options="optionsCategorias" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedCategorias" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "categorias",
    props:{
        placeholderm:String,
        mltp:{
            default:false,
            type:Boolean
        }
    },
    data(){
        return{
            selectedCategorias:null,
            optionsCategorias:[]
        }
    },
    mounted() {
        this.getCategorias();
    },
    methods:{
        getCategorias(){
            let $this = this;
            axios.post('/api/AdminCatalogos/categoriasSelect')
                .then(r => {
                        $this.optionsCategorias = r.data;
                        //console.log(r);
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setCambio(){
            this.$emit('cambioCategoria');
        }
    }
}
</script>

<style scoped>

</style>
